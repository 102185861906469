import { Grid, Menu, MenuItem, Paper, Stack } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { GrPowerReset } from "react-icons/gr";
import { useParams } from "react-router-dom";
import "rsuite/dist/rsuite-no-reset.min.css";
import { deepEqual, distinctFilter, findDataRange, getUniqueValuesFromJson } from "../utils/dataManipulation";
import { LoadingOrEmptyWrapper } from "../components/LoadingAndEmptyHandler";
import "react-grid-layout/css/styles.css";
import { TfiSave } from "react-icons/tfi";
import "react-resizable/css/styles.css";
import { ButtonNew, Chip, CustomModal, Input, Table } from "../components";
// import { GeneralErrorBoundary } from "../ErrorBoundary";
import { useSelector } from "react-redux";
import { AssetCardModal } from "../components/AssetOverview/AssetCardModal";
import { Icon } from "../components";
import { DynamicFilterFactory } from "../components/DynamicFormInput";
import { DynamicDashboard } from "../components/chart/DynamicDashboard";
import { settingsOptions, useDynamicDashboard, useDynamicDashboardMultiTab } from "../utils/dynamicDashboard";
import { chartTypes, diagramTypes } from "../enums/dynamicDashboard";
import { FilterTypes } from "../enums/components";
import { columnsNamesEnums, columnsUnits, dataMapping, FiltersNames, isRelevantOption } from "../enums/AssetOverview/portfolio";
import { useGetAllBuildings } from "../api/hooks/allHooks";
import { diagramInitialConfig } from "../components/chart/DynamicChartMemoized";
import { ScrollableTabsButtonAuto } from "../components/Tabs";
import { LabelLessInput } from "../components/Input";
import { Divider } from "../components/Divider";
import { Dropdown, DropdownWithGroup } from "../components/Dropdown";

const filtersCategories = [
  {
    categoryName: 'Building profile',
    filtersNames: [
      columnsNamesEnums.PORTFOLIO,
      columnsNamesEnums.BUILDING_ID,
      columnsNamesEnums.BUILDING_NAME,
      columnsNamesEnums.USE_TYPE,
      columnsNamesEnums.BOUGHT_ON,
      columnsNamesEnums.SOLD_ON,
      columnsNamesEnums.RENTAL_UNITS,
      columnsNamesEnums.YEAR_OF_CONSTRUCTION,
      columnsNamesEnums.FLOOR_AMOUMT,
      columnsNamesEnums.HERITAGE_PROTECTED,
      columnsNamesEnums.BUILDING_TYPE_ID,
      columnsNamesEnums.BUILDING_TYPE,
      columnsNamesEnums.PRE_FABRICATED,
      columnsNamesEnums.LOGGIA,
      columnsNamesEnums.BALCONY,
      columnsNamesEnums.FOOTPRINT,
      columnsNamesEnums.CEILING_AREA,
      columnsNamesEnums.FACADE_AREA,
      columnsNamesEnums.PREMISE,
      columnsNamesEnums.GROSS_AREA_WITH_BASEMENT,
      columnsNamesEnums.GROSS_LETTABLE_AREA_WIGHOUT_BASEMENT,
      columnsNamesEnums.NET_AREA_WITH_BASEMENT,
      columnsNamesEnums.NET_LETTABEL_AREA_WITHOUT_BASEMENT,
      columnsNamesEnums.ENERGY_EFFICIENCY_CLASS,
      // columnsNamesEnums.ACCOUNTING_AREA,

    ],
  },
  {
    categoryName: 'Geography',
    filtersNames: [
      columnsNamesEnums.ZIP,
      columnsNamesEnums.CITY,
      columnsNamesEnums.MAIN_DISTRICT,
      columnsNamesEnums.SUB_DISTRICT,
      columnsNamesEnums.FULL_ADDRESS,
      columnsNamesEnums.STREET,
    ],
  },
  {
    categoryName: 'Energy & CO2',
    filtersNames: [
      columnsNamesEnums.EPC_CONSUMPTION_KWH_M2A,
      columnsNamesEnums.HEAT_ENERGY_CONSUMPTION_EPC_KWH_M2_A,
      columnsNamesEnums.HEAT_ENERGY_CONSUMPTION_EPC_KWH_A,
      columnsNamesEnums.ELECTRICITY_DEMAND_KWH_A,
      columnsNamesEnums.ELECTRICITY_DEMAND_KWH_M2A,
      columnsNamesEnums.HEAT_ENERGY_TYPE,
      columnsNamesEnums.DISTRICT_HEATING_AVAILABLE,
      columnsNamesEnums.NATURAL_GAS_AVAILABLE,
      columnsNamesEnums.TOTAL_ENERGY_CONSUMPTION,
      columnsNamesEnums.CO2_EMISSION,
      columnsNamesEnums.STRANDING_YEAR,
    ],
  },
]

const defaultPortfolioConfigs = [
  {
    "tab": "Portfolio summary",
    "filters": {
      "Energy Efficiency class": [
        null,
        "C",
        "A",
        "B"
      ],
      "Gross area": [
        29000,
        51430
      ],
      "Energy consumption": [
        3.63,
        66
      ],
      "Heat energy consumption": [
        16.35,
        123
      ],
      "Electrictiy consumption": [
        48.95,
        54
      ]
    },
    "settings": [
      {
        "type": "1",
        "id": 1,
        "x": 7,
        "y": 5,
        "w": 5,
        "h": 6,
        "minW": 3,
        "minH": 3,
        "maxH": 20,
        "draggable": true,
        "diagrams": [
          {
            "dataPoint": null,
            "dataKey": "Footprint area",
            "diagramType": "scatter",
            "color": "#0adb53",
            "partitions": null,
            "stacks": null,
            "percentageBased": false,
            "direction": "horizontal",
            "title": null
          }
        ],
        "sortValue": null,
        "chartType": "composed",
        "title": "Footprint VS Premise",
        "xAxis": "Premise area",
        "yAxis": null,
        "dataPoint": null,
        "legendsPosition": "bottom"
      },
      {
        "type": "1",
        "id": 2,
        "x": 7,
        "y": 0,
        "w": 5,
        "h": 5,
        "minW": 3,
        "minH": 3,
        "maxH": 20,
        "draggable": true,
        "diagrams": [
          {
            "dataPoint": null,
            "dataKey": "CO2 Emissions",
            "diagramType": "scatter",
            "color": "#f91010",
            "partitions": null,
            "stacks": null,
            "percentageBased": false,
            "direction": "horizontal",
            "title": null
          }
        ],
        "sortValue": null,
        "chartType": "bar",
        "title": "CO2 Emissions per Usage",
        "xAxis": "Main usage",
        "yAxis": null,
        "dataPoint": "Gross area",
        "legendsPosition": "bottom"
      },
      {
        "type": "1",
        "id": 3,
        "x": 0,
        "y": 5,
        "w": 7,
        "h": 6,
        "minW": 3,
        "minH": 3,
        "maxH": 20,
        "draggable": true,
        "diagrams": [
          {
            "dataPoint": null,
            "dataKey": null,
            "diagramType": null,
            "color": "#f61313",
            "partitions": null,
            "stacks": null,
            "percentageBased": false,
            "direction": "horizontal",
            "title": null
          }
        ],
        "sortValue": null,
        "chartType": "heatmap",
        "title": "Gross area ",
        "xAxis": "Energy Efficiency class",
        "yAxis": "Main usage",
        "dataPoint": "Gross area",
        "legendsPosition": "bottom"
      },
      {
        "type": "1",
        "id": 4,
        "x": 4,
        "y": 0,
        "w": 3,
        "h": 5,
        "minW": 3,
        "minH": 3,
        "maxH": 20,
        "draggable": true,
        "diagrams": [
          {
            "dataPoint": null,
            "dataKey": null,
            "diagramType": null,
            "color": "black",
            "partitions": "Main usage",
            "stacks": null,
            "percentageBased": false,
            "direction": "vertical",
            "title": null
          }
        ],
        "sortValue": null,
        "chartType": "kpi",
        "title": "Footprint area",
        "xAxis": null,
        "yAxis": null,
        "dataPoint": "Footprint area",
        "legendsPosition": "bottom"
      },
      {
        "type": "1",
        "id": 5,
        "x": 0,
        "y": 0,
        "w": 4,
        "h": 5,
        "minW": 3,
        "minH": 3,
        "maxH": 20,
        "draggable": true,
        "diagrams": [
          {
            "dataPoint": null,
            "dataKey": null,
            "diagramType": null,
            "color": "black",
            "partitions": null,
            "stacks": null,
            "percentageBased": false,
            "direction": "horizontal",
            "title": null
          }
        ],
        "sortValue": null,
        "chartType": "miniMap",
        "title": "Heat Energy Consumption",
        "xAxis": null,
        "yAxis": null,
        "dataPoint": "Heat energy consumption",
        "legendsPosition": "bottom"
      }
    ],
    "selectedFiltersNames": []
  },
  {
    "filters": {
      "Main usage": [
        "Office"
      ]
    },
    "settings": [
      {
        "type": "1",
        "id": 0,
        "x": 0,
        "y": 0,
        "w": 4,
        "h": 5,
        "minW": 3,
        "minH": 3,
        "maxH": 20,
        "draggable": true,
        "diagrams": [
          {
            "dataPoint": null,
            "dataKey": null,
            "diagramType": null,
            "color": "black",
            "partitions": null,
            "stacks": null,
            "percentageBased": false,
            "direction": "horizontal",
            "title": null
          }
        ],
        "sortValue": null,
        "chartType": "bar",
        "title": "Energy Consumption",
        "xAxis": "Heat energy type",
        "yAxis": null,
        "dataPoint": "Energy consumption",
        "legendsPosition": "bottom"
      }
    ],
    "selectedFiltersNames": [
      "Main usage"
    ],
    "tab": "Office"
  }
]

const newEmptyTab = {
  "tab": "",
  filters: {},
  settings: [],
  selectedFiltersNames: []
}

const defaultPortfolioFilters = defaultPortfolioConfigs.filters

const defaultPortfolioSettings = defaultPortfolioConfigs.settings

const defaultPortfolioSelectedFiltersName = defaultPortfolioConfigs.selectedFiltersNames

const defaultPortfolioSelectedFilters = filtersCategories.map(category => {
  return { ...category, selectedFiltersNames: defaultPortfolioSelectedFiltersName?.filter(name => category.filtersNames.includes(name)) }
})


const defaultMinW = 3;
const defaultMinH = 3;

const defaultChart = {
  type: "1",
  // i: "0",
  id: 0,
  x: 0,
  y: 0,
  w: defaultMinW + 1,
  h: defaultMinH + 2,
  minW: defaultMinW,
  minH: defaultMinH,
  maxH: 20,
  draggable: true,
  diagrams: [diagramInitialConfig],
  sortValue: null,
  chartType: null,
  title: null,
  xAxis: null,
  yAxis: null,
  dataPoint: null,
  legendsPosition: 'bottom',
};


const loadDashboardConfigs = () => {
  let dashboardConfigs = localStorage.getItem("portfolioConfigs");
  if (dashboardConfigs) {
    dashboardConfigs = JSON.parse(dashboardConfigs);
  } else {
    dashboardConfigs = defaultPortfolioConfigs;
  }
  return dashboardConfigs
};


const handleConfigChange = (index, diagrams, key, newValue, updateChart) => {
  const newConfig = { ...diagrams[index], [key]: newValue };
  const updatedConfig = diagrams?.map((diagram, i) => {
    return i !== index ? diagram : newConfig;
  });
  updateChart('diagrams', updatedConfig);
};


const DashboardTab = ({ value, setValue, onDelete, hadDeleteIcon }) => {
  const onChange = (e) => {
    setValue(e.target.value)
  }

  const [hovered, setHovered] = useState(false)

  return (
    <>
      {hovered &&
        <input
          style={{
            border: 'none'
          }}
          className="w-40 h-8 p-2 text-center t-subheading-m capitalize bg-transparent"
          value={value}
          // className={className}
          onChange={onChange}
          onBlur={() => {
            setHovered(false)
          }}
        // onMouseLeave={() => setHovered(false)}
        />
      }
      {!hovered &&
        <span
          // className={className}
          // onMouseEnter={() => setHovered(true)}
          // onMouseLeave={() => setHovered(false)}
          onClick={() => setHovered(true)}
          // onDoubleClick={() => setHovered(false)}
          className="w-40 h-8 text-center p-2 t-subheading-m capitalize bg-transparent cursor-pointer"

        >
          {value}
        </span>
      }
      {hadDeleteIcon &&
        <Icon onClick={onDelete} iconName={'Close'} size='sm' color={'white'}
          className='absolute top-2 right-2' />
      }
    </>
  )

}


const Header = ({ portfolioConfigs,
  defaultDashboardConfigsHasChanged,
  dashboardConfigsHasChanged,
  onResetDashboardConfigs,
  saveDashboardConfigs,
  addChart,
  tabs,
  addTab,
  deleteTab,
  updateTabName,
  currentTabIndex,
  setCurrentTabIndex,
}) => {

  const { t } = useTranslation();


  const tabsLabelsComponents = tabs?.map((tab, index) => {
    return {
      label: <DashboardTab
        value={tab}
        setValue={(newValue) => { updateTabName(newValue, index) }}
        onDelete={() => deleteTab(index)}
        hadDeleteIcon={!(tabs?.length === 1)}
      />
    }
  })


  return (
    <Stack className=" -ml-4 justify-between">
      <ScrollableTabsButtonAuto
        tabs={tabs}
        addTab={addTab}
        tabsLabelsComponents={tabsLabelsComponents}
        setCurrentTabIndex={setCurrentTabIndex}
        currentTabIndex={currentTabIndex}
        className=' flex-1 max-w-[800px]' />
      {/* <span>
        {dashboardConfigsHasChanged(allChartsConfigs) && (
          <span className="t-heading-m">*</span>
        )}

      </span> */}

      <Stack gap={4} className="items-center justify-end w-150">
        {defaultDashboardConfigsHasChanged && (
          <ButtonNew
            variant="primary"
            size="md"
            onClick={onResetDashboardConfigs}
          >
            <Stack gap={2}>
              <GrPowerReset className="cursor-pointer w-4 h-4" />
              <span>Reset To Default</span>
            </Stack>
          </ButtonNew>
        )}
        {dashboardConfigsHasChanged && (
          <>
            <ButtonNew
              variant="primary"
              size="md"
              onClick={() => saveDashboardConfigs(portfolioConfigs)}
            >
              <Stack gap={2}>
                <TfiSave className="cursor-pointer w-4 h-4" />
                <span>Save</span>
              </Stack>
            </ButtonNew>
          </>
        )}
        <ButtonNew variant="primary" size="md" onClick={addChart}>
          +Add new chart
        </ButtonNew>
      </Stack>
    </Stack>

  )


}


const Filters = ({ filterCategories, removeFilterName, addFilterName, data, setFilteredData, setConfig }) => {

  const [showID, setShowID] = useState(undefined)

  const [anchorEl, setAnchorEl] = useState(null);
  const [showFiltersModal, setShowFiltersModal] = useState(false)
  const [filtersInfoIsVisible, setFiltersInfoIsVisible] = useState(true)
  const [activeFilterCategoryName, setActiveFilterCategoryName] = useState(filterCategories?.[0]?.categoryName)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    if (!data?.length) return

    let partiallyFilteredData = [...data]
    filterCategories.forEach(filterCategory => {
      filterCategory?.filters
        ?.filter(filter => filterCategory.selectedFiltersNames?.includes(filter?.filterName))
        ?.forEach(filter => {
          const { filterType, mainDataKey, ...args } = filter
          if (!mainDataKey) return
          const filterFactory = new DynamicFilterFactory(filterType, args)
          const filterInstance = filterFactory.createFilterClassInstance()
          partiallyFilteredData = filterInstance.filterData(partiallyFilteredData, mainDataKey)
        })
    })

    setFilteredData(partiallyFilteredData)
  }, [data, filterCategories])

  return (
    <>
      <div className="relative pl-4 ">

        {/* Filters Info  */}
        <Stack className={`py-4 items-start ${!filtersInfoIsVisible && 'w-0 overflow-hidden h-5'}`}
        >
          <ButtonNew
            variant="secondary"
            size="md"
            onClick={() => setShowFiltersModal(true)}
            className='mr-8'
          >
            <Stack gap={2}>
              <Icon size={'md'} iconName={'Filter'} />
              <span>Filters</span>
            </Stack>
          </ButtonNew>

          <Stack className="flex-wrap items-start gap-4">
            {filterCategories?.map((filterCategory, index) => {
              return filterCategory?.selectedFiltersNames?.map(name => {
                const filter = filterCategory?.filters?.find(filter => filter?.filterName === name)
                let { filterType, ...args } = filter
                args = {
                  ...args, props: {
                    ...args.props,
                    onContextMenu: (e) => {
                      e.preventDefault();
                      setActiveFilterCategoryName(filterCategory.categoryName)
                      setShowFiltersModal(true)
                    },
                  }
                }
                const filterFactory = new DynamicFilterFactory(filterType, args)
                const filterInstance = filterFactory.createFilterClassInstance()
                if (filterInstance) {
                  const FilterComponent = filterInstance.createComponent()
                  return (
                    <Stack className="gap-1 w-80">
                      <Icon size={'md'} iconName={'Remove'} color='var(--clr-mystic-red-500)'
                        onClick={() => {
                          removeFilterName(name)
                        }}
                      />
                      {FilterComponent}
                    </Stack>
                  )


                }
              }


              )
            }
            )}

          </Stack >


        </Stack>

        {/* Filters fold/expand icon */}
        <Stack className={`w-4 min-h-[2.4rem] absolute z-100 bg-blue-50 top-1 bottom-1 left-0 -ml-4 
          items-center justify-center t-numbers-m hover:bg-blue-200 cursor-pointer 
          `}
          onClick={() => setFiltersInfoIsVisible(filtersInfoIsVisible => !filtersInfoIsVisible)}
        >
          <Icon size={'sm'} iconName={'ChevronRight'} className={`${filtersInfoIsVisible && 'rotate-180'} `} />
        </Stack>
      </div>

      {/* Filters Modal */}
      <CustomModal
        modalOpen={showFiltersModal}
        setModalOpen={setShowFiltersModal}
        width='60rem'
        height='90%'
        top={24}
        right={24}
        left='unset'
        style={{ transform: 'none' }}
      >
        <h3 className="t-heading-l">Filter Catergories</h3>
        <Stack className="py-4 flex-col gap-2 items-center w-full">
          <Grid className="w-full" container spacing={4}>
            {filterCategories?.map((filterCategory, index) => {
              const isActive = filterCategory.categoryName == activeFilterCategoryName
              return <Grid item xs={4}>
                <Chip
                  isOn={isActive}
                  onClick={() => setActiveFilterCategoryName(filterCategory.categoryName)}
                >
                  <span>{filterCategory.categoryName}</span>
                </Chip>
              </Grid>

            })}

          </Grid>

          <Divider className='mt-4' />
          <Stack className="mt-8 w-full flex-col items-center">

            {filterCategories?.map((filterCategory, index) => {
              if (filterCategory.categoryName === activeFilterCategoryName)
                return (
                  <Stack className="w-full px-3 gap-2" flexDirection={'column'}>
                    <ButtonNew
                      onClick={(e) => {
                        setShowID(index)
                        handleClick(e)
                      }}
                      variant="secondary"
                      size="sm"
                      className="mt-1  mb-4"
                      id={"basic-button" + index}
                      aria-controls={(showID === index && open) ? ('basic-menu' + index) : undefined}
                      aria-haspopup="true"
                      aria-expanded={(showID === index && open) ? 'true' : undefined}
                    >
                      + Add filter
                    </ButtonNew>
                    <Menu
                      id={"basic-menu" + index}
                      anchorEl={anchorEl}
                      open={showID === index && open}
                      onClose={handleClose}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button' + index,
                        disablePadding: true
                      }}
                      sx={{
                        '.MuiMenu-paper': {
                          padding: '8px 0'
                        }
                      }}
                    >
                      {filterCategory?.filters?.map(f => {
                        if (filterCategory.selectedFiltersNames?.includes(f?.filterName)) return
                        return (
                          <MenuItem className=""
                            onClick={() => {
                              handleClose()
                              setShowID(undefined)
                              setConfig(f?.filterName, undefined)
                              addFilterName(f?.filterName)
                            }
                            }>
                            <Stack className="w-full justify-between gap-8 t-body-l">
                              <span>
                                {f?.filterName}
                              </span>
                              <span>+</span>
                            </Stack>
                          </MenuItem>

                        )
                      })}
                    </Menu>
                    {
                      filterCategory?.selectedFiltersNames?.map(name => {
                        const filter = filterCategory?.filters?.find(filter => filter?.filterName === name)
                        let { filterType, ...args } = filter
                        if (filterType === FilterTypes.NUMERIC_RANGE_PICKER)
                          args = { ...args, props: { ...args.props, changeByTyping: true } }
                        const filterFactory = new DynamicFilterFactory(filterType, args)
                        const filterInstance = filterFactory.createFilterClassInstance()
                        if (filterInstance) {
                          const FilterComponent = filterInstance.createComponent()
                          return (
                            <Stack className="gap-1 w-full">
                              <Icon size={'md'} iconName={'Remove'} color='var(--clr-mystic-red-500)'
                                onClick={() => {
                                  removeFilterName(name)
                                }}
                              />
                              {FilterComponent}
                            </Stack>
                          )


                        }
                      })
                    }

                  </Stack>

                )
            }
            )}
          </Stack>

        </Stack >
      </CustomModal>

    </>

  )
}





const adjustConfigs = (configs) => {
  return JSON.stringify(configs)
}


export const Portfolio = ({ }) => {
  const { t } = useTranslation()

  const { user } = useSelector((store) => store.user);
  const { data, isLoading } = useGetAllBuildings(user?.user_id);
  // const dataMapping = {
  //   'id': "ID",
  //   "name": "Name",
  //   "address": "Addresse",
  //   "portfolio": "Portfolio",
  //   "main_usage": "Main usage",
  //   'premise_area': "Premise area",
  //   'footprint_area': "Footprint area",
  //   'gross_asset_size': "Gross area",
  //   'floor_amount': "Floor amount",
  //   'construction_year': "Construction year",
  //   "energy_label": "Energy Efficiency class",
  //   'stranding_year': "Stranding year",
  //   'energy_consumption': "Energy consumption",
  //   'carbon_dioxide_emission_value': "CO2 Emissions",
  //   "heat_energy_type": "Heat energy type",
  //   'heat_energy_consumption': "Heat energy consumption",
  //   'electricity_consumption': "Electrictiy consumption",
  //   'latitude': 'lat',
  //   'longitude': 'lon',
  // }
  const generalDashboardData = useMemo(() => {
    const mappedData = []
    data?.forEach((row) => {
      const mappedRow = {}
      Object.keys(row).forEach((oldDataKey) => {
        if (Object.keys(dataMapping).includes(oldDataKey)) {
          const newDataKey = dataMapping[oldDataKey]
          mappedRow[newDataKey] = row[oldDataKey]
        }
        // else {
        //   mappedRow[oldDataKey] = row[oldDataKey]
        // }
        mappedRow[columnsNamesEnums.COUNT] = 1
      })
      mappedData.push(mappedRow)
    })
    return mappedData

  }, [data])

  const savedPortfolioConfigs = loadDashboardConfigs()

  const [portfolioConfigs, setPortfolioConfigs] = useState(savedPortfolioConfigs)

  const saveDashboardConfigs = () => {
    localStorage.setItem("portfolioConfigs", JSON.stringify(portfolioConfigs));
    b(!a);
  };

  const onResetDashboardConfigs = () => {
    setPortfolioConfigs(defaultPortfolioConfigs)
    localStorage.removeItem("portfolioConfigs");
  };

  const [a, b] = useState(false);

  const dashboardConfigsHasChanged = !deepEqual(
    adjustConfigs(savedPortfolioConfigs),
    adjustConfigs(portfolioConfigs))
  const defaultDashboardConfigsHasChanged = !deepEqual(
    adjustConfigs(defaultPortfolioConfigs),
    adjustConfigs(portfolioConfigs))

  let dataColumns = [],
    categoricalColumns = [],
    numericalColumns = [];
  if (generalDashboardData?.length) {
    dataColumns = Object.keys(generalDashboardData[0]);
    categoricalColumns = [
      FiltersNames.ID,
      FiltersNames.NAME,
      FiltersNames.PORTFOLIO,
      FiltersNames.MAIN_USAGE,
      FiltersNames.ENERGY_EFFICIENCY_CLASS,
      FiltersNames.HEAT_ENERGY_TYPE,
    ]
    numericalColumns = [
      FiltersNames.COUNT,
      FiltersNames.PREMISE_AREA,
      FiltersNames.FOOTPRINT_AREA,
      FiltersNames.GROSS_AREA,
      FiltersNames.FLOOR_AMOUMT,
      FiltersNames.CONSTRUCTION_YEAR,
      FiltersNames.STRANDING_YEAR,
      FiltersNames.ENERGY_CONSUMPTION,
      FiltersNames.CO2_EMISSION,
      FiltersNames.HEAT_ENERGY_CONSUMPTION,
      FiltersNames.ELECTRICITY_CONSUMPTION,
      FiltersNames.CASH_IN,
      FiltersNames.LATITUDE,
      FiltersNames.LONGITUDE,
      FiltersNames.GROSS_ASSET_VALUE,
    ]
  }

  const dataColumnsOptionsGroups = filtersCategories?.map(category => {
    const sortedOptions = [...category.filtersNames]

    sortedOptions.sort()
    return { group: category.categoryName, options: sortedOptions }
  })

  dataColumnsOptionsGroups.unshift({ group: 'Reset & Count', options: [columnsNamesEnums.NONE, columnsNamesEnums.INDEX, columnsNamesEnums.COUNT] })


  const [filteredData, setFilteredData] = useState(generalDashboardData)

  const tabs = portfolioConfigs?.map(tabConfigs => tabConfigs.tab)

  const [currentTabIndex, setCurrentTabIndex] = useState(0)

  const headers = [
    {
      label: "Name",
      dataKey: columnsNamesEnums.BUILDING_NAME,
      cellType: null,
      valueTransformation: (row) => <Stack gap={2}>
        <img className="w-12 h-12" src={row.image_url_value} />
        <span>
          {row[columnsNamesEnums.BUILDING_NAME]}
        </span>
      </Stack>,
      subValueTransformation: (row) => null,
      valueClassName: 'py-1',
      downloadZipFile: (row) => null,
      href: (row) => null,
      isInDefault: true,
    },
    {
      label: 'Portfolio',
      dataKey: columnsNamesEnums.PORTFOLIO,
      cellType: "",
      valueTransformation: (row) => row[columnsNamesEnums.PORTFOLIO],
      subValueTransformation: (row) => null,
      downloadZipFile: (row) => null,
      href: (row) => null,
      isInDefault: true,
    },
    {
      label: 'Heat energy type',
      dataKey: columnsNamesEnums.HEAT_ENERGY_TYPE,
      cellType: null,
      valueTransformation: (row) => row[columnsNamesEnums.HEAT_ENERGY_TYPE],
      subValueTransformation: (row) => null,
      downloadZipFile: (row) => null,
      href: (row) => null,
      isInDefault: true,
    },

    {
      label: 'Main usage',
      dataKey: columnsNamesEnums.USE_TYPE,
      cellType: null,
      valueTransformation: (row) => row[columnsNamesEnums.USE_TYPE],
      subValueTransformation: (row) => null,
      downloadZipFile: (row) => null,
      href: (row) => null,
      isInDefault: true,
    },
    {
      label: 'Energy consumption',
      dataKey: columnsNamesEnums.TOTAL_ENERGY_CONSUMPTION,
      cellType: null,
      valueTransformation: (row) => row[columnsNamesEnums.TOTAL_ENERGY_CONSUMPTION],
      subValueTransformation: (row) => null,
      downloadZipFile: (row) => null,
      href: (row) => null,
      isInDefault: true,
    },
    {
      label: 'CO2 Emissions',
      dataKey: columnsNamesEnums.CO2_EMISSION,
      cellType: null,
      valueTransformation: (row) => row[columnsNamesEnums.CO2_EMISSION],
      subValueTransformation: (row) => null,
      downloadZipFile: (row) => null,
      href: (row) => null,
      isInDefault: true,
    },
  ]

  const tabConfigs = portfolioConfigs[currentTabIndex]

  useEffect(() => {
    const tabConfigs = portfolioConfigs[currentTabIndex]
    if (!tabConfigs && portfolioConfigs?.length)
      setCurrentTabIndex(0)

  }, [portfolioConfigs])

  const allChartsConfigs = tabConfigs?.settings
  const setAllChartsConfigs = (tabAllChartsConfigs) => setPortfolioConfigs(portfoliosConfigs => {
    // console.log(currentTabIndex, portfolioConfigs, tabAllChartsConfigs)
    const newPortfoliosConfigs = portfoliosConfigs?.map((tabConfigs, i) => {
      if (i !== currentTabIndex) return tabConfigs
      return { ...tabConfigs, settings: tabAllChartsConfigs }
    })
    return newPortfoliosConfigs
  })

  const allFiltersConfig = tabConfigs?.filters
  const setAllFiltersConfig = (tabAllFiltersConfigs) => setPortfolioConfigs(portfoliosConfigs => {
    const newPortfoliosConfigs = portfoliosConfigs?.map((tabConfigs, i) => {
      if (i !== currentTabIndex) return tabConfigs
      return { ...tabConfigs, filters: tabAllFiltersConfigs }
    })
    return newPortfoliosConfigs
  })

  // const selectedFiltersNames = tabConfigs.selectedFiltersNames
  // const setSelectedFiltersNames = (tabSelectedFiltersNames) => setPortfolioConfigs(portfoliosConfigs => {
  //   const newPortfoliosConfigs = portfoliosConfigs?.map((_tabConfigs, i) => {
  //     if (i !== currentTabIndex) return tabConfigs
  //     return { ...tabConfigs, selectedFiltersNames: tabSelectedFiltersNames }
  //   })
  //   return newPortfoliosConfigs
  // })


  const selectedFiltersNames = useMemo(() => filtersCategories.map(category => {
    return { ...category, selectedFiltersNames: tabConfigs?.selectedFiltersNames?.filter(name => category.filtersNames.includes(name)) }
  }), [portfolioConfigs, currentTabIndex])

  const setSelectedFiltersNames = (tabSelectedFiltersNames) => setPortfolioConfigs(portfoliosConfigs => {
    const newPortfoliosConfigs = portfoliosConfigs?.map((tabConfigs, i) => {
      if (i !== currentTabIndex) return tabConfigs
      return { ...tabConfigs, selectedFiltersNames: tabSelectedFiltersNames }
    })
    return newPortfoliosConfigs
  })


  const addFilterName = (name) => {
    setPortfolioConfigs(portfoliosConfigs => {
      const newPortfoliosConfigs = portfoliosConfigs?.map((tabConfigs, i) => {
        if (i !== currentTabIndex) return tabConfigs
        const newFilterNames = tabConfigs.selectedFiltersNames
        newFilterNames.push(name)
        return { ...tabConfigs, selectedFiltersNames: newFilterNames }
      })
      return newPortfoliosConfigs
    })
  }

  const removeFilterName = (name) => {
    setPortfolioConfigs(portfoliosConfigs => {
      const newPortfoliosConfigs = portfoliosConfigs?.map((tabConfigs, i) => {
        if (i !== currentTabIndex) return tabConfigs
        const newFilterNames = tabConfigs.selectedFiltersNames?.filter(filterName => filterName !== name)
        return { ...tabConfigs, selectedFiltersNames: newFilterNames }
      })
      return newPortfoliosConfigs
    })
  }

  const updateTabName = (newTabName, index) => setPortfolioConfigs(portfoliosConfigs => {
    const updatedPortfoliosConfigs = portfoliosConfigs?.map((tabConfigs, i) => {
      if (i !== index) return tabConfigs
      return { ...tabConfigs, tab: newTabName }
    })
    return updatedPortfoliosConfigs
  })

  const addTab = () => {
    setCurrentTabIndex(currentTabIndex => portfolioConfigs?.length)
    setPortfolioConfigs(portfolioConfigs => {
      return [...portfolioConfigs, {
        filters: {},
        settings: [],
        selectedFiltersNames: [], tab: `New Tab`
      }]

    })
  }

  const deleteTab = (index) => {
    setPortfolioConfigs(portfolioConfigs => {
      return portfolioConfigs?.filter((_tabConfigs, i) => index !== i)
    })
    setCurrentTabIndex(currentTabIndex => index === 0 ? 0 : index - 1)

  }
  const scrollRef = useRef()

  const addChartNotWorking = () => {
    const maxY = allChartsConfigs?.length
      ? Math.max(...allChartsConfigs?.map((l) => l.y + l.h))
      : -1;
    const maxId = allChartsConfigs?.length
      ? Math.max(...allChartsConfigs?.map((l) => l.id))
      : -1;
    const newChart = {
      ...defaultChart,
      y: maxY + 1,
      id: maxId + 1,
    };

    setAllChartsConfigs([...allChartsConfigs, newChart]);
    scrollRef.current?.scrollIntoView({
      behavior: "smooth",
    });
  };

  const addChart = () => {
    setPortfolioConfigs(portfoliosConfigs => {
      const allChartsConfigs = portfoliosConfigs[currentTabIndex]?.settings
      const maxY = allChartsConfigs?.length
        ? Math.max(...allChartsConfigs?.map((l) => l.y + l.h))
        : -1;
      const maxId = allChartsConfigs?.length
        ? Math.max(...allChartsConfigs?.map((l) => l.id))
        : -1;
      const newChart = {
        ...defaultChart,
        y: maxY + 1,
        id: maxId + 1,
      };

      const newallChartsConfigs = [...allChartsConfigs, newChart]
      const newPortfoliosConfigs = portfoliosConfigs?.map((tabConfigs, i) => {
        if (i !== currentTabIndex) return tabConfigs
        return { ...tabConfigs, settings: newallChartsConfigs }
      })
      return newPortfoliosConfigs
    })

    scrollRef.current?.scrollIntoView({
      behavior: "smooth",
    });
  };


  const removeChartNotWorking = (id) => {
    setAllChartsConfigs(allChartsConfigs.filter((l) => l.id !== id));
  };

  const removeChart = (id) => {
    setPortfolioConfigs(portfoliosConfigs => {
      const allChartsConfigs = portfoliosConfigs[currentTabIndex]?.settings
      const newallChartsConfigs = allChartsConfigs.filter((l) => l.id !== id)
      const newPortfoliosConfigs = portfoliosConfigs?.map((tabConfigs, i) => {
        if (i !== currentTabIndex) return tabConfigs
        return { ...tabConfigs, settings: newallChartsConfigs }
      })
      return newPortfoliosConfigs
    })
  };



  const updateChartNotWorking = (id, key, value) => {
    console.log(id, key, value, 'allChartsConfigs = ', allChartsConfigs, 'tabConfigs = ', tabConfigs, 'portfolioConfigs = ', portfolioConfigs)
    setAllChartsConfigs(allChartsConfigs.map((l) => {
      if (l.id !== id) return l;
      else return { ...l, [key]: value };
    }))
  };

  const updateChart = (id, key, value) => {
    setPortfolioConfigs(portfoliosConfigs => {
      const allChartsConfigs = portfoliosConfigs[currentTabIndex]?.settings
      const newallChartsConfigs = allChartsConfigs.map((l) => {
        if (l.id !== id) return l;
        else return { ...l, [key]: value };
      })
      const newPortfoliosConfigs = portfoliosConfigs?.map((tabConfigs, i) => {
        if (i !== currentTabIndex) return tabConfigs
        return { ...tabConfigs, settings: newallChartsConfigs }
      })
      return newPortfoliosConfigs
    })
  };

  if (allChartsConfigs)
    return (
      <LoadingOrEmptyWrapper showLoading={isLoading} height="400px">
        <article
          className="bg-white mt-8 px-4 pb-4 rounded-xl overflow-hidden"
        >
          <Header
            portfolioConfigs={portfolioConfigs}
            updateChart={updateChart}
            defaultDashboardConfigsHasChanged={defaultDashboardConfigsHasChanged}
            dashboardConfigsHasChanged={dashboardConfigsHasChanged}
            onResetDashboardConfigs={onResetDashboardConfigs}
            saveDashboardConfigs={saveDashboardConfigs}
            addChart={addChart}
            tabs={tabs}
            addTab={addTab}
            deleteTab={deleteTab}
            updateTabName={updateTabName}
            currentTabIndex={currentTabIndex}
            setCurrentTabIndex={setCurrentTabIndex}
          />
          <PortfolioTab
            key={currentTabIndex + portfolioConfigs}
            filteredData={filteredData}
            setFilteredData={setFilteredData}
            dataColumns={dataColumns}
            dataColumnsOptionsGroups={dataColumnsOptionsGroups}
            categoricalColumns={categoricalColumns}
            numericalColumns={numericalColumns}
            generalDashboardData={generalDashboardData}
            allChartsConfigs={allChartsConfigs}
            setAllChartsConfigs={setAllChartsConfigs}
            updateChart={updateChart}
            removeChart={removeChart}
            allFiltersConfig={allFiltersConfig}
            setAllFiltersConfig={setAllFiltersConfig}
            selectedFiltersNames={selectedFiltersNames}
            setSelectedFiltersNames={setSelectedFiltersNames}
            removeFilterName={removeFilterName}
            addFilterName={addFilterName}
            scrollRef={scrollRef}
            data={data}
          />
          {/* <span ref={scrollRef}>Scroll ref</span> */}

        </article>
        <Paper className="mt-8">
          <h4 className="t-heading-m ">
            {filteredData?.length} {t("AssetOverview.filters.buildings")}
          </h4>
          <Table
            className="relative mt-6"
            data={[...filteredData]}
            headers={headers}
            idColumnName="id"
            columnsMinWidth="10rem"
          />
        </Paper>

      </LoadingOrEmptyWrapper >
    );
};


export const PortfolioTab = ({
  filteredData,
  setFilteredData,
  dataColumns,
  dataColumnsOptionsGroups,
  categoricalColumns,
  numericalColumns,
  generalDashboardData,
  allChartsConfigs,
  setAllChartsConfigs,
  updateChart,
  removeChart,
  allFiltersConfig,
  setAllFiltersConfig,
  selectedFiltersNames,
  setSelectedFiltersNames,
  removeFilterName,
  addFilterName,
  scrollRef,
  data
}) => {

  const setConfig = (filterName, value) => {
    setAllFiltersConfig({ ...allFiltersConfig, [filterName]: value })
  }

  const allAvailableFilters = useMemo(() => [
    {
      filterName: columnsNamesEnums.PORTFOLIO,
      mainDataKey: columnsNamesEnums.PORTFOLIO,
      filterType: FilterTypes.DROPDOWN_MULTI,
      config: allFiltersConfig,
      setConfig,
      props: {
        width: "100%",
        height: '4.8rem',
        label: columnsNamesEnums.PORTFOLIO,
        options: getUniqueValuesFromJson(generalDashboardData, columnsNamesEnums.PORTFOLIO),
      },
    },
    {
      filterName: columnsNamesEnums.USE_TYPE,
      mainDataKey: columnsNamesEnums.USE_TYPE,
      filterType: FilterTypes.DROPDOWN_MULTI,
      config: allFiltersConfig,
      setConfig,
      props: {
        width: "100%",
        height: '4.8rem',
        label: columnsNamesEnums.USE_TYPE,
        options: getUniqueValuesFromJson(generalDashboardData, columnsNamesEnums.USE_TYPE),
      },
    },
    {
      filterName: columnsNamesEnums.ENERGY_EFFICIENCY_CLASS,
      filterType: FilterTypes.DROPDOWN_MULTI,
      mainDataKey: columnsNamesEnums.ENERGY_EFFICIENCY_CLASS,
      props: {
        width: "100%",
        height: '4.8rem',
        label: columnsNamesEnums.ENERGY_EFFICIENCY_CLASS,
        options: getUniqueValuesFromJson(generalDashboardData, columnsNamesEnums.ENERGY_EFFICIENCY_CLASS),
      },
      config: allFiltersConfig,
      setConfig,
    },
    {
      filterName: columnsNamesEnums.HEAT_ENERGY_TYPE,
      filterType: FilterTypes.DROPDOWN_MULTI,
      mainDataKey: columnsNamesEnums.HEAT_ENERGY_TYPE,
      config: allFiltersConfig,
      setConfig,
      props: {
        width: "100%",
        height: '4.8rem',
        label: columnsNamesEnums.HEAT_ENERGY_TYPE,
        options: getUniqueValuesFromJson(generalDashboardData, columnsNamesEnums.HEAT_ENERGY_TYPE),
      },
    },
    {
      filterName: columnsNamesEnums.PREMISE,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsNamesEnums.PREMISE,
      config: allFiltersConfig,
      setConfig,
      props: {
        className: 'w-full',
        label: columnsNamesEnums.PREMISE,
        ...findDataRange({ data: generalDashboardData, dataKeys: [columnsNamesEnums.PREMISE], defaultMin: 0 })

      },
    },
    {
      filterName: columnsNamesEnums.FOOTPRINT,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsNamesEnums.FOOTPRINT,
      config: allFiltersConfig,
      setConfig,
      props: {
        className: 'w-full',
        label: columnsNamesEnums.FOOTPRINT,
        ...findDataRange({ data: generalDashboardData, dataKeys: [columnsNamesEnums.FOOTPRINT], defaultMin: 0 })

      },
    },
    {
      filterName: columnsNamesEnums.GROSS_AREA_WITH_BASEMENT,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsNamesEnums.GROSS_AREA_WITH_BASEMENT,
      config: allFiltersConfig,
      setConfig,
      props: {
        className: 'w-full',
        label: columnsNamesEnums.GROSS_AREA_WITH_BASEMENT,
        ...findDataRange({ data: generalDashboardData, dataKeys: [columnsNamesEnums.GROSS_AREA_WITH_BASEMENT], defaultMin: 0 })

      },
    },
    {
      filterName: columnsNamesEnums.GROSS_LETTABLE_AREA_WIGHOUT_BASEMENT,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsNamesEnums.GROSS_LETTABLE_AREA_WIGHOUT_BASEMENT,
      config: allFiltersConfig,
      setConfig,
      props: {
        className: 'w-full',
        label: columnsNamesEnums.GROSS_LETTABLE_AREA_WIGHOUT_BASEMENT,
        ...findDataRange({ data: generalDashboardData, dataKeys: [columnsNamesEnums.GROSS_LETTABLE_AREA_WIGHOUT_BASEMENT], defaultMin: 0 })
      },
    },

    {
      filterName: columnsNamesEnums.FLOOR_AMOUMT,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsNamesEnums.FLOOR_AMOUMT,
      config: allFiltersConfig,
      setConfig,
      props: {
        className: 'w-full',
        label: columnsNamesEnums.FLOOR_AMOUMT,
        step: 1,
        ...findDataRange({
          data: generalDashboardData, dataKeys: [columnsNamesEnums.FLOOR_AMOUMT], defaultMin: 0
        })
      },
    },

    {
      filterName: columnsNamesEnums.YEAR_OF_CONSTRUCTION,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsNamesEnums.YEAR_OF_CONSTRUCTION,
      config: allFiltersConfig,
      setConfig,
      props: {
        step: 1,
        className: 'w-full',
        label: columnsNamesEnums.YEAR_OF_CONSTRUCTION,
        ...findDataRange({
          data: generalDashboardData, dataKeys: [columnsNamesEnums.YEAR_OF_CONSTRUCTION],
          defaultMin: 1900
        }),
      },
    },

    {
      filterName: columnsNamesEnums.STRANDING_YEAR,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsNamesEnums.STRANDING_YEAR,
      config: allFiltersConfig,
      setConfig,
      props: {
        step: 1,
        className: 'w-full',
        label: columnsNamesEnums.STRANDING_YEAR,
        ...findDataRange({
          data: generalDashboardData, dataKeys: [columnsNamesEnums.STRANDING_YEAR],
          defaultMin: 1900

        })

      },
    },

    {
      filterName: columnsNamesEnums.TOTAL_ENERGY_CONSUMPTION,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsNamesEnums.TOTAL_ENERGY_CONSUMPTION,
      config: allFiltersConfig,
      setConfig,
      props: {
        className: 'w-full',
        label: columnsNamesEnums.TOTAL_ENERGY_CONSUMPTION,
        ...findDataRange({ data: generalDashboardData, dataKeys: [columnsNamesEnums.TOTAL_ENERGY_CONSUMPTION], defaultMin: 0 })

      },
    },

    {
      filterName: columnsNamesEnums.CO2_EMISSION,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsNamesEnums.CO2_EMISSION,
      config: allFiltersConfig,
      setConfig,
      props: {
        className: 'w-full',
        label: columnsNamesEnums.CO2_EMISSION,
        ...findDataRange({ data: generalDashboardData, dataKeys: [columnsNamesEnums.CO2_EMISSION], defaultMin: 0 })

      },
    },
    {
      filterName: columnsNamesEnums.HEAT_ENERGY_CONSUMPTION_EPC_KWH_A,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsNamesEnums.HEAT_ENERGY_CONSUMPTION_EPC_KWH_A,
      config: allFiltersConfig,
      setConfig,
      props: {
        className: 'w-full',
        label: columnsNamesEnums.HEAT_ENERGY_CONSUMPTION_EPC_KWH_A,
        ...findDataRange({ data: generalDashboardData, dataKeys: [columnsNamesEnums.HEAT_ENERGY_CONSUMPTION_EPC_KWH_A], defaultMin: 0 })
      },
    },
    {
      filterName: columnsNamesEnums.HEAT_ENERGY_CONSUMPTION_EPC_KWH_M2_A,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsNamesEnums.HEAT_ENERGY_CONSUMPTION_EPC_KWH_M2_A,
      config: allFiltersConfig,
      setConfig,
      props: {
        className: 'w-full',
        label: columnsNamesEnums.HEAT_ENERGY_CONSUMPTION_EPC_KWH_M2_A,
        ...findDataRange({ data: generalDashboardData, dataKeys: [columnsNamesEnums.HEAT_ENERGY_CONSUMPTION_EPC_KWH_M2_A], defaultMin: 0 })
      },
    },
    {
      filterName: columnsNamesEnums.ELECTRICITY_DEMAND_KWH_A,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsNamesEnums.ELECTRICITY_DEMAND_KWH_A,
      config: allFiltersConfig,
      setConfig,
      props: {
        className: 'w-full',
        label: columnsNamesEnums.ELECTRICITY_DEMAND_KWH_A,
        ...findDataRange({ data: generalDashboardData, dataKeys: [columnsNamesEnums.ELECTRICITY_DEMAND_KWH_A], defaultMin: 0 })
      },
    },
    {
      filterName: columnsNamesEnums.ELECTRICITY_DEMAND_KWH_M2A,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsNamesEnums.ELECTRICITY_DEMAND_KWH_M2A,
      config: allFiltersConfig,
      setConfig,
      props: {
        className: 'w-full',
        label: columnsNamesEnums.ELECTRICITY_DEMAND_KWH_M2A,
        ...findDataRange({ data: generalDashboardData, dataKeys: [columnsNamesEnums.ELECTRICITY_DEMAND_KWH_M2A], defaultMin: 0 })
      },
    },
    // ////////////////////////////////////////////////////////////////////////////////////

    {
      filterName: columnsNamesEnums.HERITAGE_PROTECTED,
      filterType: FilterTypes.DROPDOWN_MULTI, mainDataKey: columnsNamesEnums.HERITAGE_PROTECTED, config: allFiltersConfig, setConfig,
      props: {
        className: 'w-full',
        label: columnsNamesEnums.HERITAGE_PROTECTED,
        width: "100%",
        height: '4.8rem',
        options: getUniqueValuesFromJson(generalDashboardData, columnsNamesEnums.HERITAGE_PROTECTED),
      },
    },
    {
      filterName: columnsNamesEnums.BUILDING_TYPE_ID,
      filterType: FilterTypes.DROPDOWN_MULTI, mainDataKey: columnsNamesEnums.BUILDING_TYPE_ID, config: allFiltersConfig, setConfig, props: {
        className: 'w-full',
        label: columnsNamesEnums.BUILDING_TYPE_ID,
        width: "100%",
        height: '4.8rem',
        options: getUniqueValuesFromJson(generalDashboardData, columnsNamesEnums.BUILDING_TYPE_ID),
      }
    },
    {
      filterName: columnsNamesEnums.BUILDING_TYPE,
      filterType: FilterTypes.DROPDOWN_MULTI, mainDataKey: columnsNamesEnums.BUILDING_TYPE, config: allFiltersConfig, setConfig, props: {
        className: 'w-full',
        label: columnsNamesEnums.BUILDING_TYPE,
        width: "100%",
        height: '4.8rem',
        options: getUniqueValuesFromJson(generalDashboardData, columnsNamesEnums.BUILDING_TYPE),
      }
    },
    {
      filterName: columnsNamesEnums.PRE_FABRICATED,
      filterType: FilterTypes.DROPDOWN_MULTI, mainDataKey: columnsNamesEnums.PRE_FABRICATED, config: allFiltersConfig, setConfig, props: {
        className: 'w-full',
        label: columnsNamesEnums.PRE_FABRICATED,
        width: "100%",
        height: '4.8rem',
        options: getUniqueValuesFromJson(generalDashboardData, columnsNamesEnums.PRE_FABRICATED),
      }
    },
    {
      filterName: columnsNamesEnums.LOGGIA,
      filterType: FilterTypes.DROPDOWN_MULTI, mainDataKey: columnsNamesEnums.LOGGIA, config: allFiltersConfig, setConfig, props: {
        className: 'w-full',
        label: columnsNamesEnums.LOGGIA,
        width: "100%",
        height: '4.8rem',
        options: getUniqueValuesFromJson(generalDashboardData, columnsNamesEnums.LOGGIA),
      }
    },
    {
      filterName: columnsNamesEnums.BALCONY,
      filterType: FilterTypes.DROPDOWN_MULTI, mainDataKey: columnsNamesEnums.BALCONY, config: allFiltersConfig, setConfig, props: {
        className: 'w-full',
        label: columnsNamesEnums.BALCONY,
        width: "100%",
        height: '4.8rem',
        options: getUniqueValuesFromJson(generalDashboardData, columnsNamesEnums.BALCONY),
      }
    },
    {
      filterName: columnsNamesEnums.CEILING_AREA,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER, mainDataKey: columnsNamesEnums.CEILING_AREA, config: allFiltersConfig, setConfig, props: {
        className: 'w-full',
        label: columnsNamesEnums.CEILING_AREA,
        ...findDataRange({ data: generalDashboardData, dataKeys: [columnsNamesEnums.CEILING_AREA], defaultMin: 0 })
      }
    },
    {
      filterName: columnsNamesEnums.FACADE_AREA,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER, mainDataKey: columnsNamesEnums.FACADE_AREA, config: allFiltersConfig, setConfig, props: {
        className: 'w-full',
        label: columnsNamesEnums.FACADE_AREA,
        ...findDataRange({ data: generalDashboardData, dataKeys: [columnsNamesEnums.FACADE_AREA], defaultMin: 0 })
      }
    },
    {
      filterName: columnsNamesEnums.NET_AREA_WITH_BASEMENT,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER, mainDataKey: columnsNamesEnums.NET_AREA_WITH_BASEMENT, config: allFiltersConfig, setConfig, props: {
        className: 'w-full',
        label: columnsNamesEnums.NET_AREA_WITH_BASEMENT,
        ...findDataRange({ data: generalDashboardData, dataKeys: [columnsNamesEnums.NET_AREA_WITH_BASEMENT], defaultMin: 0 })
      }
    },
    {
      filterName: columnsNamesEnums.NET_LETTABEL_AREA_WITHOUT_BASEMENT,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER, mainDataKey: columnsNamesEnums.NET_LETTABEL_AREA_WITHOUT_BASEMENT, config: allFiltersConfig, setConfig, props: {
        className: 'w-full',
        label: columnsNamesEnums.NET_LETTABEL_AREA_WITHOUT_BASEMENT,
        ...findDataRange({ data: generalDashboardData, dataKeys: [columnsNamesEnums.NET_LETTABEL_AREA_WITHOUT_BASEMENT], defaultMin: 0 })
      }
    },
    {
      filterName: columnsNamesEnums.EPC_CONSUMPTION_KWH_M2A,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER, mainDataKey: columnsNamesEnums.EPC_CONSUMPTION_KWH_M2A, config: allFiltersConfig, setConfig, props: {
        className: 'w-full',
        label: columnsNamesEnums.EPC_CONSUMPTION_KWH_M2A,
        ...findDataRange({ data: generalDashboardData, dataKeys: [columnsNamesEnums.EPC_CONSUMPTION_KWH_M2A], defaultMin: 0 })
      }
    },
    {
      filterName: columnsNamesEnums.DISTRICT_HEATING_AVAILABLE,
      filterType: FilterTypes.DROPDOWN_MULTI, mainDataKey: columnsNamesEnums.DISTRICT_HEATING_AVAILABLE, config: allFiltersConfig, setConfig, props: {
        className: 'w-full',
        label: columnsNamesEnums.DISTRICT_HEATING_AVAILABLE,
        width: "100%",
        height: '4.8rem',
        options: getUniqueValuesFromJson(generalDashboardData, columnsNamesEnums.DISTRICT_HEATING_AVAILABLE),
      }
    },
    {
      filterName: columnsNamesEnums.NATURAL_GAS_AVAILABLE,
      filterType: FilterTypes.DROPDOWN_MULTI, mainDataKey: columnsNamesEnums.NATURAL_GAS_AVAILABLE, config: allFiltersConfig, setConfig, props: {
        className: 'w-full',
        label: columnsNamesEnums.NATURAL_GAS_AVAILABLE,
        width: "100%",
        height: '4.8rem',
        options: getUniqueValuesFromJson(generalDashboardData, columnsNamesEnums.NATURAL_GAS_AVAILABLE),
      }
    },
    {
      filterName: columnsNamesEnums.BOUGHT_ON,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER, mainDataKey: columnsNamesEnums.BOUGHT_ON, config: allFiltersConfig, setConfig, props: {
        className: 'w-full',
        label: columnsNamesEnums.BOUGHT_ON,
        ...findDataRange({ data: generalDashboardData, dataKeys: [columnsNamesEnums.BOUGHT_ON], defaultMin: 0 })
      }
    },
    {
      filterName: columnsNamesEnums.SOLD_ON,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER, mainDataKey: columnsNamesEnums.SOLD_ON, config: allFiltersConfig, setConfig, props: {
        className: 'w-full',
        label: columnsNamesEnums.SOLD_ON,
        ...findDataRange({ data: generalDashboardData, dataKeys: [columnsNamesEnums.SOLD_ON], defaultMin: 0 })
      }
    },
    {
      filterName: columnsNamesEnums.RENTAL_UNITS,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER, mainDataKey: columnsNamesEnums.RENTAL_UNITS, config: allFiltersConfig, setConfig, props: {
        className: 'w-full',
        label: columnsNamesEnums.RENTAL_UNITS,
        ...findDataRange({ data: generalDashboardData, dataKeys: [columnsNamesEnums.RENTAL_UNITS], defaultMin: 0 })
      }
    },
    {
      filterName: columnsNamesEnums.BUILDING_ID,
      filterType: FilterTypes.DROPDOWN_MULTI, mainDataKey: columnsNamesEnums.BUILDING_ID, config: allFiltersConfig, setConfig, props: {
        className: 'w-full',
        label: columnsNamesEnums.BUILDING_ID,
        width: "100%",
        height: '4.8rem',
        options: getUniqueValuesFromJson(generalDashboardData, columnsNamesEnums.BUILDING_ID),
      }
    },
    {
      filterName: columnsNamesEnums.BUILDING_NAME,
      filterType: FilterTypes.DROPDOWN_MULTI, mainDataKey: columnsNamesEnums.BUILDING_NAME, config: allFiltersConfig, setConfig, props: {
        className: 'w-full',
        label: columnsNamesEnums.BUILDING_NAME,
        width: "100%",
        height: '4.8rem',
        options: getUniqueValuesFromJson(generalDashboardData, columnsNamesEnums.BUILDING_NAME),
      }
    },
    {
      filterName: columnsNamesEnums.ZIP,
      filterType: FilterTypes.DROPDOWN_MULTI, mainDataKey: columnsNamesEnums.ZIP, config: allFiltersConfig, setConfig, props: {
        className: 'w-full',
        label: columnsNamesEnums.ZIP,
        width: "100%",
        height: '4.8rem',
        options: getUniqueValuesFromJson(generalDashboardData, columnsNamesEnums.ZIP),
      }
    },
    {
      filterName: columnsNamesEnums.CITY,
      filterType: FilterTypes.DROPDOWN_MULTI, mainDataKey: columnsNamesEnums.CITY, config: allFiltersConfig, setConfig, props: {
        className: 'w-full',
        label: columnsNamesEnums.CITY,
        width: "100%",
        height: '4.8rem',
        options: getUniqueValuesFromJson(generalDashboardData, columnsNamesEnums.CITY),
      }
    },
    {
      filterName: columnsNamesEnums.MAIN_DISTRICT,
      filterType: FilterTypes.DROPDOWN_MULTI, mainDataKey: columnsNamesEnums.MAIN_DISTRICT, config: allFiltersConfig, setConfig, props: {
        className: 'w-full',
        label: columnsNamesEnums.MAIN_DISTRICT,
        width: "100%",
        height: '4.8rem',
        options: getUniqueValuesFromJson(generalDashboardData, columnsNamesEnums.MAIN_DISTRICT),
      }
    },
    {
      filterName: columnsNamesEnums.SUB_DISTRICT,
      filterType: FilterTypes.DROPDOWN_MULTI, mainDataKey: columnsNamesEnums.SUB_DISTRICT, config: allFiltersConfig, setConfig, props: {
        className: 'w-full',
        label: columnsNamesEnums.SUB_DISTRICT,
        width: "100%",
        height: '4.8rem',
        options: getUniqueValuesFromJson(generalDashboardData, columnsNamesEnums.SUB_DISTRICT),
      }
    },
    {
      filterName: columnsNamesEnums.FULL_ADDRESS,
      filterType: FilterTypes.DROPDOWN_MULTI, mainDataKey: columnsNamesEnums.FULL_ADDRESS, config: allFiltersConfig, setConfig, props: {
        className: 'w-full',
        label: columnsNamesEnums.FULL_ADDRESS,
        width: "100%",
        height: '4.8rem',
        options: getUniqueValuesFromJson(generalDashboardData, columnsNamesEnums.FULL_ADDRESS),
      }
    },
    {
      filterName: columnsNamesEnums.STREET,
      filterType: FilterTypes.DROPDOWN_MULTI, mainDataKey: columnsNamesEnums.STREET, config: allFiltersConfig, setConfig, props: {
        className: 'w-full',
        label: columnsNamesEnums.STREET,
        width: "100%",
        height: '4.8rem',
        options: getUniqueValuesFromJson(generalDashboardData, columnsNamesEnums.STREET),
      }
    }

  ], [generalDashboardData, allFiltersConfig])

  const selectedFilters = useMemo(() => {
    return selectedFiltersNames.map(filterCategory => {
      const filters = filterCategory?.filtersNames?.map(name => allAvailableFilters?.find(filter => name === filter?.filterName))
      return { ...filterCategory, filters }
    })
  }, [selectedFiltersNames, allFiltersConfig, generalDashboardData])

  const dynamicChartsRowHeight = 50;

  const sortOptions = []
  const allSettings = (updateChart, config) => [
    {
      filterName: FiltersNames.TITLE,
      filterLabel: 'Chart title',
      filterType: FilterTypes.INPUT,
      adjustConfig: (config) => config,
      setConfig: (updateChart) => updateChart,
      props: {
        className: "block w-3/4 ",
        label: "Title",
        placeholder: "Title",
        height: "4rem"
      },
      visible: () => isRelevantOption(config.chartType, settingsOptions.TITLE),
      isInDiagramsPart: false,
    },
    {
      mainDataKey: 'dataPoint',
      useAsDataFilter: false,
      filterName: FiltersNames.DATAPOINT,
      filterLabel: 'Data point',
      filterType: FilterTypes.DROPDOWN_SINGLE,
      adjustConfig: (config) => config,
      setConfig: (updateChart) => updateChart,
      props: {
        label: "Data point",
        options: dataColumns,
        optionsGroups: dataColumnsOptionsGroups,
        width: "75%",
      },
      visible: () => isRelevantOption(config.chartType, settingsOptions.DATA_POINT),
      isInDiagramsPart: false,
    },
    {
      filterName: FiltersNames.X_AXIS,
      filterLabel: 'X Axis',
      filterType: FilterTypes.DROPDOWN_SINGLE,
      adjustConfig: (config) => config,
      setConfig: (updateChart) => updateChart,
      props: {
        label: "x-axis",
        options: dataColumns,
        optionsGroups: dataColumnsOptionsGroups,
        className: "w-3/4"
      },
      visible: () => ([chartTypes.COMPOSED, chartTypes.HISTOGRAM].includes(config.chartType)) &&
        isRelevantOption(config.chartType, settingsOptions.X,),
      isInDiagramsPart: false,
    },
    {
      filterName: FiltersNames.X_AXIS,
      filterLabel: 'X Axis',
      filterType: FilterTypes.DROPDOWN_SINGLE,
      adjustConfig: (config) => config,
      setConfig: (updateChart) => updateChart,
      props: {
        label: "x-axis",
        options: [columnsNamesEnums.NONE, columnsNamesEnums.INDEX, ...dataColumns],
        displayOptions: ["None", columnsNamesEnums.INDEX, ...dataColumns,],
        optionsGroups: dataColumnsOptionsGroups,
        className: "w-3/4"
      },
      visible: () => ([chartTypes.BAR, chartTypes.AREA, chartTypes.LINE, chartTypes.HEATMAP].includes(config.chartType)) &&
        isRelevantOption(config.chartType, settingsOptions.X,),
      isInDiagramsPart: false,
    },
    {
      filterName: FiltersNames.Y_AXIS,
      filterLabel: 'Y Axis',
      filterType: FilterTypes.DROPDOWN_SINGLE,
      adjustConfig: (config) => config,
      setConfig: (updateChart) => updateChart,
      props: {
        label: "y-axis",
        options: [columnsNamesEnums.NONE, columnsNamesEnums.INDEX, ...dataColumns],
        displayOptions: ["None", columnsNamesEnums.INDEX, ...dataColumns,],
        optionsGroups: dataColumnsOptionsGroups,
        className: "w-3/4"
      },
      visible: () => isRelevantOption(config.chartType, settingsOptions.Y,),
      isInDiagramsPart: false,
    },
    {
      filterName: FiltersNames.LEGEND_POSITION,
      filterLabel: 'Lengeds Position',
      filterType: FilterTypes.DROPDOWN_SINGLE,
      adjustConfig: (config) => config,
      setConfig: (updateChart) => updateChart,
      props: {
        label: "Lengeds position",
        options: ['bottom', 'right'],
        displayOptions: ['Bottom', 'Right'],
        width: "75%"
      },
      visible: () => isRelevantOption(config.chartType, settingsOptions.LEGENDS_POSITION),
      isInDiagramsPart: false,
    },
    {
      filterName: FiltersNames.SORT_VALUE,
      filterLabel: 'Sort by',
      filterType: FilterTypes.DROPDOWN_SINGLE,
      adjustConfig: (config) => config,
      setConfig: (updateChart) => updateChart,
      props: {
        label: "Sort by",
        options: ["", "All values", ...sortOptions],
        displayOptions: ["None", "All values", ...sortOptions],
        className: "block w-3/4 "
      },
      visible: () => isRelevantOption(config.chartType, settingsOptions.SORT_BY),
      isInDiagramsPart: false,
    },
    {
      filterName: FiltersNames.DIAGRAMS_DIAGRAM_DATA_KEY,
      filterLabel: 'Y Axis',
      filterType: FilterTypes.DROPDOWN_SINGLE,
      adjustConfig: (config) => config,
      adjustSetConfigFn: (index) => (filterName, value) => handleConfigChange(index, config.diagrams, filterName, value, updateChart),
      props: {
        label: "y-axis",
        options: dataColumns,
        optionsGroups: dataColumnsOptionsGroups,
        className: "block w-3/4 ",
      },
      visible: (diagramIndex) => isRelevantOption(config.chartType, settingsOptions.QUANTITY, diagramIndex),
      isInDiagramsPart: true,
    },
    {
      filterName: FiltersNames.DIAGRAMS_DIAGRAM_TYPE,
      filterLabel: 'Diagram type',
      filterType: FilterTypes.DROPDOWN_SINGLE,
      adjustConfig: (config) => config,
      adjustSetConfigFn: (index) => (filterName, value) => handleConfigChange(index, config.diagrams, filterName, value, updateChart),
      props: {
        label: "Diagram type",
        options: Object.values(diagramTypes),
        className: "block w-3/4 ",
      },
      visible: (diagramIndex) => isRelevantOption(config.chartType, settingsOptions.DIAGRAM_TYPE, diagramIndex),
      isInDiagramsPart: true,
    },
    {
      filterName: FiltersNames.DIAGRAMS_PARTITIONS,
      filterLabel: 'Partitions',
      filterType: FilterTypes.DROPDOWN_SINGLE,
      adjustConfig: (config) => config,
      adjustSetConfigFn: (index) => (filterName, value) => handleConfigChange(index, config.diagrams, filterName, value, updateChart),
      props: {
        label: "Partitions",
        options: [columnsNamesEnums.NONE, ...dataColumns],
        displayOptions: ["None", ...dataColumns,],
        optionsGroups: dataColumnsOptionsGroups,
        className: "block w-3/4 ",
      },
      visible: (diagramIndex) => isRelevantOption(config.chartType, settingsOptions.PARTITIONS, diagramIndex),
      isInDiagramsPart: true,
    },
    {
      filterName: FiltersNames.DIAGRAMS_STACKS,
      filterLabel: 'Stacks',
      filterType: FilterTypes.DROPDOWN_SINGLE,
      adjustConfig: (config) => config,
      adjustSetConfigFn: (index) => (filterName, value) => handleConfigChange(index, config.diagrams, filterName, value, updateChart),
      props: {
        label: "Stacks",
        options: [columnsNamesEnums.NONE, ...dataColumns],
        displayOptions: ["None", ...dataColumns,],
        optionsGroups: dataColumnsOptionsGroups,
        className: "block w-3/4 ",
      },
      visible: (diagramIndex) => isRelevantOption(config.chartType, settingsOptions.STACKS, diagramIndex),
      isInDiagramsPart: true,
    },
    {
      filterName: FiltersNames.DIAGRAMS_DIRECTION,
      filterLabel: 'Direction',
      filterType: FilterTypes.DROPDOWN_SINGLE,
      adjustConfig: (config) => config,
      adjustSetConfigFn: (index) => (filterName, value) => handleConfigChange(index, config.diagrams, filterName, value, updateChart),
      props: {
        label: "Direction",
        options: ["horizontal", "vertical"],
        displayOptions: ["Horizontal", "Vertical"],
        className: "block w-3/4 ",
      },
      visible: (diagramIndex) => isRelevantOption(config.chartType, settingsOptions.DIRECTION, diagramIndex),
      isInDiagramsPart: true,
    },
    {
      filterLabel: 'Color',
      filterName: FiltersNames.DIAGRAMS_COLOR,
      filterType: FilterTypes.COLOR_INPUT,
      adjustConfig: (config) => config,
      adjustSetConfigFn: (index) => (filterName, value) => handleConfigChange(index, config.diagrams, filterName, value, updateChart),
      props: {
        className: "block w-3/4 ",
      },
      visible: (diagramIndex) => isRelevantOption(config.chartType, settingsOptions.COLOR, diagramIndex),
      isInDiagramsPart: true,
    },
  ]

  const useGetSpecificData = (_config) => {
    return { specificData: [], isLoading: false, isError: false }
  }

  const dataTransformator = useCallback((data, config) => {
    const { dataPoint, chartType, partitions, stacks, xDataKey, yDataKey } = config;

    if ([chartTypes.DOUGHNUT, chartTypes.PIE].includes(chartType)) {

      const flattenData = data?.map((row) => {
        const filteredRow = {};
        if (partitions) filteredRow[partitions] = row[partitions];
        if (stacks) filteredRow[stacks] = row[stacks];
        return {
          ...filteredRow,
          dataPoint,
          [dataPoint]: row[dataPoint],
        };
      });
      return flattenData || []
    }

    else if ([chartTypes.AREA, chartTypes.BAR, chartTypes.LINE, chartTypes.KPI].includes(chartType)) {

      const flattenData = data?.map((row) => {
        const filteredRow = {};
        if (partitions) filteredRow[partitions] = row[partitions];
        if (stacks) filteredRow[stacks] = row[stacks];
        if (xDataKey) filteredRow[xDataKey] = row[xDataKey];
        return {
          ...filteredRow,
          dataPoint,
          [dataPoint]: row[dataPoint],
        };
      });
      return flattenData || []
    }

    else if (chartType === chartTypes.HEATMAP) {

      const flattenData = data?.map((row) => {
        const filteredRow = {};
        if (yDataKey) filteredRow[yDataKey] = row[yDataKey];
        if (xDataKey) filteredRow[xDataKey] = row[xDataKey];
        return {
          ...filteredRow,
          dataPoint,
          [dataPoint]: row[dataPoint],
        };
      });
      const xLabels = data
        ?.map((row) => row[xDataKey])
        ?.filter(distinctFilter);

      const yLabels = data
        ?.map((row) => row[yDataKey])
        ?.filter(distinctFilter);

      return { flattenData: flattenData || [], xLabels, yLabels }
    }

    return []

  }, [])

  const [assetId, setAssetId] = useState()
  const [triggerFlag, setTriggerFlag] = useState(false)

  const eventHandlers = useCallback((point, config) => {
    const { dataPoint, chartType, partitions, stacks, xDataKey, yDataKey } = config;

    if (chartType === chartTypes.MINI_MAP) {
      return {
        click: (point) => {
          setAssetId(point?.[columnsNamesEnums.ID])
          setTriggerFlag(triggerFlag => !triggerFlag)
        },
      }
    }

  }, [])


  const getUnit = ({ quantity }) => {
    return columnsUnits?.[quantity]
  }
  return (
    <>
      <Filters
        filterCategories={selectedFilters}
        setFilteredData={setFilteredData}
        data={generalDashboardData}
        // setFilterCategories={setSelectedFiltersNames}
        setConfig={setConfig}
        removeFilterName={removeFilterName}
        addFilterName={addFilterName}
      />

      <AssetCardModal triggerFlag={triggerFlag} onCardClick={() => {
        window.open(`${window.location.origin}/details/${assetId}/buildingProfile`)
      }}
        building={data?.find(building => building?.id === assetId)}
      />

      <DynamicDashboard
        allChartsConfigs={allChartsConfigs}
        setAllChartsConfigs={setAllChartsConfigs}
        dynamicChartsRowHeight={dynamicChartsRowHeight}
        generalDashboardData={filteredData}
        allFilters={() => []}
        allSettings={allSettings}
        specificDataGetter={useGetSpecificData}
        dataTransformator={dataTransformator}
        removeChart={removeChart}
        updateChart={updateChart}
        eventHandlers={eventHandlers}
        scrollRef={scrollRef}
        getUnit={getUnit}
      />
    </>
  );
};

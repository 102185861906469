import { Paper, Stack } from "@mui/material";
import { Loading } from "../Loading";
import { BulletChartAxis } from "./BulletChartAxis";
import { BulletChartColorBar } from "./BulletChartColorBar";
import BulletChartLegend from "./BulletChartLegend";
import { BulletChartZones } from "./BulletChartZones";
import { Pill } from "./Pill";
import { LoadingOrEmptyWrapper } from "../LoadingAndEmptyHandler";

export const BulletChart = ({
  className,
  bulletColorBarClassName,
  header,
  pill,
  bullets,
  legends,
  axis,
  zones,
  hasZones = false,
  isLoading,
}) => {
  return (
    <LoadingOrEmptyWrapper showLoading={isLoading} height="16rem">
      {/* <Paper className={`${className} `}> */}
      <div className={className}>
        {/* Header */}
        <Stack spacing={4}>
          <h2 className="t-heading-l">{header}</h2>
          <Pill>{pill}</Pill>
        </Stack>

        <BulletChartLegend
          legends={legends}
          hasZones={hasZones}
          className="mt-4"
        />
        {/* Chart */}
        <div className="relative pt-8 mt-6 h-23">
          <BulletChartColorBar bullets={bullets} axis={axis} className={`h-4`} gradientClassName={bulletColorBarClassName} />
          <BulletChartAxis axis={axis} className="mt-6" />
          {hasZones && (
            <BulletChartZones zones={zones} axis={axis} className="mt-6" />
          )}
        </div>
        {/* </Paper> */}
      </div>
    </LoadingOrEmptyWrapper>
  );
};

import { TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import React, { useState } from "react";
import styled from "styled-components";
import SearchIcon from "../Asset/Icons/search.svg";
import { Icon } from "./Icon";
export const Input = ({
  inputRef,
  label,
  placeholder,
  className,
  type,
  id,
  height,
  width,
  onChange,
  endAdornment,
  value,
  ...props
}) => {
  const [isEmpty, setIsEmpty] = useState(!value);
  const [HasError, setHasError] = useState(false);
  const inputBorderColor = HasError
    ? "var(--clr-mystic-red)"
    : isEmpty
      ? "var(--clr-gray-400)"
      : "var(--clr-gray-900)";

  const inputLabelColor = HasError
    ? "var(--clr-mystic-red)"
    : "var(--clr-gray-600)";
  return (
    <span
      className={className}
      style={{
        display: "block",
        width: width,
      }}
      {...props}
    >
      <CustomTextField
        bordercolor={inputBorderColor}
        textcolor={inputBorderColor}
        labelcolor={inputLabelColor}
        autoComplete="on"
        height={height}
        InputProps={endAdornment ? { endAdornment } : null}
        InputLabelProps={{
          style: {
            color: inputLabelColor,
            fontFamily: "Nunito Sans",
            fontSize: "1.4rem",
            fontWeight: 600,
            lineHeight: "1.4rem",
            letterSpacing: "0.025rem",
            textTransform: "capitalize",
            transform: type === "search" ? "translate(1.2rem, 1.4rem)" : "",
          },
        }}
        id={id}
        label={label}
        placeholder={placeholder}
        type={`${type === "search" ? "" : type}`}
        displayshrinkedlabel={type === "search" ? "false" : "true"}
        variant="filled"
        inputRef={inputRef}
        fullWidth
        onChange={(e) => {
          if (inputRef)
            if (inputRef.current === "") setIsEmpty(true);
            else {
              setIsEmpty(inputRef.current.value === "");
            }
          else setIsEmpty(e.target.value === "");
          if (onChange) onChange(e);
        }}
        value={value}
      />
    </span>
  );
};

export const PasswordInput = ({
  inputRef,
  label,
  placeholder,
  className,
  id,
  height,
  width,
  onChange,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const endAdornment = showPassword ? "on" : "off";
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  return (
    <Input
      inputRef={inputRef}
      label={label}
      placeholder={placeholder}
      className={className}
      type={showPassword ? "text" : "password"}
      id={id}
      height={height}
      width={width}
      onChange={onChange}
      endAdornment={
        <InputAdornment position="end">
          {showPassword ? (
            <Icon
              className="cursor-pointer"
              size="sm"
              onClick={handleClickShowPassword}
              iconName="EyeClose"
              svgClassName="w-4 h-4"
            />
          ) : (
            <Icon
              className="cursor-pointer"
              size="sm"
              onClick={handleClickShowPassword}
              iconName="EyeOpen"
              svgClassName="w-4 h-4"
            />
          )}
        </InputAdornment>
      }
    />
  );
};

export const SearchInput = ({
  value,
  label,
  placeholder,
  className,
  type,
  id,
  height,
  width,
  onChange,
  onBlur,
  style,
}) => {
  const [isEmpty, setIsEmpty] = useState(true);
  const [HasError, setHasError] = useState(false);
  const inputBorderColor = HasError
    ? "var(--clr-mystic-red)"
    : isEmpty
      ? "var(--clr-gray-400)"
      : "var(--clr-gray-900)";

  const inputLabelColor = HasError
    ? "var(--clr-mystic-red)"
    : "var(--clr-gray-600)";
  return (
    <span
      className={className}
      style={{
        display: "block",
        width: width,
        ...style,
      }}
    >
      <CustomTextField
        bordercolor={inputBorderColor}
        textcolor={inputBorderColor}
        labelcolor={inputLabelColor}
        height={height}
        InputProps={
          type === "search"
            ? {
              endAdornment: (
                <InputAdornment position="end">
                  <img src={SearchIcon} alt="Search Icon" />
                </InputAdornment>
              ),
            }
            : null
        }
        InputLabelProps={{
          style: {
            color: inputLabelColor,
            fontFamily: "Nunito Sans",
            fontSize: "1.4rem",
            fontWeight: 600,
            lineHeight: "1.4rem",
            letterSpacing: "0.025rem",
            textTransform: "capitalize",
            transform: type === "search" ? "translate(1.2rem, 1.4rem)" : "",
          },
        }}
        id={id}
        label={label}
        placeholder={placeholder}
        type={`${type === "search" ? "" : type}`}
        displayshrinkedlabel={type === "search" ? "false" : "true"}
        variant="filled"
        value={value}
        fullWidth
        onChange={(e) => {
          setIsEmpty(e.target.value === "");
          if (onChange) onChange(e);
        }}
        onBlur={() => {
          if (onBlur) onBlur();
        }}
      />
    </span>
  );
};

export const LabelLessInput = ({
  value,
  placeholder,
  className,
  type,
  id,
  height,
  width,
  onChange,
  onBlur,
  style,
  autoFocus,
  onKeyDown,
  ...props
}) => {
  const [isEmpty, setIsEmpty] = useState(true);
  const [HasError, setHasError] = useState(false);
  const inputBorderColor = HasError
    ? "var(--clr-mystic-red)"
    : isEmpty
      ? "var(--clr-gray-400)"
      : "var(--clr-gray-900)";

  const textColor = "var(--clr-gray-900)";

  return (
    <span
      className={className}
      style={{
        display: "block",
        width: width,
        ...style,
      }}
      {...props}
    >
      <LabelLessTextField
        bordercolor={inputBorderColor}
        autoFocus={autoFocus}
        textcolor={textColor}
        height={height}
        id={id}
        placeholder={placeholder}
        type={type}
        displayshrinkedlabel={"false"}
        variant="filled"
        value={value}
        fullWidth
        onChange={(e) => {
          setIsEmpty(e.target.value === "");
          if (onChange) onChange(e);
        }}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
      />
    </span>
  );
};

const CustomTextField = styled(TextField)(
  ({ bordercolor, textcolor, labelcolor, height, displayshrinkedlabel }) => ({
    "input:-webkit-autofill,input:-webkit-autofill:hover,input:-webkit-autofill:focus,    input:-webkit-autofill:active": {
      transition: "background-color 720000s ease-in-out 0s",
    },
    "& .MuiInputBase-root.MuiFilledInput-root": {
      height: height,
      backgroundColor: "var(--clr-white)",
      color: textcolor,
      fontFamily: "Nunito Sans",
      fontSize: "1.4rem",
      fontWeight: "400",
      lineHeight: "1.9rem",
      letterSpacing: "0rem",
      border: `1px solid ${bordercolor}`,

      borderRadius: "7px",
      "&:hover": {
        border: "1px solid var(--clr-secondary-blue-200)",
        boxShadow:
          "0px 0.2px 0.6px rgba(36, 89, 234, 0.041), 0px 0.5px 1.8px rgba(36, 89, 234, 0.059), 0px 1.2px 4.2px rgba(36, 89, 234, 0.077), 0px 4px 14px rgba(36, 89, 234, 0.11)",
      },
      "&:before, &:after": {
        border: "none",
      },
      "&:after": {
        border: "none",
      },
      "&.Mui-focused": {
        border: "1px solid var(--clr-leaftech-blue-500)",
      },
    },
    "& .MuiFormLabel-root.MuiInputLabel-root.Mui-focused": {
      color: labelcolor,
      fontFamily: "Nunito Sans",
      fontSize: "1rem",
      fontWeight: 600,
      lineHeight: "1.4rem",
      letterSpacing: "0.025rem",
    },
    "& .MuiInputBase-input.MuiFilledInput-input": {
      transform:
        displayshrinkedlabel === "true" ? "" : "translate(0rem, -0.8rem)",

      "&:before, &:after": {
        border: "none",
      },
    },
    "& .MuiInputLabel-shrink": {
      display: displayshrinkedlabel === "true" ? "block" : "none",
    },
  })
);

const LabelLessTextField = styled(TextField)(
  ({ bordercolor, textcolor, height, displayshrinkedlabel }) => ({
    "input:-webkit-autofill,input:-webkit-autofill:hover,input:-webkit-autofill:focus,    input:-webkit-autofill:active": {
      transition: "background-color 72000s ease-in-out 0s",
    },
    "& .MuiInputBase-root.MuiFilledInput-root": {
      height: height,
      backgroundColor: "var(--clr-white)",
      color: textcolor,
      fontFamily: "Nunito Sans",
      fontSize: "1.4rem",
      fontWeight: "400",
      lineHeight: "1.9rem",
      letterSpacing: "0rem",
      border: `1px solid ${bordercolor}`,

      borderRadius: "7px",
      "&:hover": {
        border: "1px solid var(--clr-secondary-blue-200)",
        boxShadow:
          "0px 0.2px 0.6px rgba(36, 89, 234, 0.041), 0px 0.5px 1.8px rgba(36, 89, 234, 0.059), 0px 1.2px 4.2px rgba(36, 89, 234, 0.077), 0px 4px 14px rgba(36, 89, 234, 0.11)",
      },
      "&:before, &:after": {
        border: "none",
      },
      "&:after": {
        border: "none",
      },
      "&.Mui-focused": {
        border: "1px solid var(--clr-leaftech-blue-500)",
      },
    },
    "& .MuiInputBase-input.MuiFilledInput-input": {
      transform:
        displayshrinkedlabel === "true" ? "" : "translate(0rem, -0.8rem)",

      "&:before, &:after": {
        border: "none",
      },
    },
    "& .MuiInputLabel-shrink": {
      display: displayshrinkedlabel === "true" ? "block" : "none",
    },
  })
);

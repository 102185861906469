export const convertDatetimeStringToDate = (datetimeString) => {
  if (!datetimeString) return;
  const datetime = new Date(datetimeString);

  const day = datetime.getDate().toString().padStart(2, "0");
  const month = (datetime.getMonth() + 1).toString().padStart(2, "0");
  const year = datetime.getFullYear().toString();
  const hour = datetime.getHours().toString().padStart(2, "0");
  const minute = datetime.getMinutes().toString().padStart(2, "0");

  const formattedDatetime = `${day}/${month}/${year} ${hour}:${minute}`;
  return formattedDatetime;
};

export const nDaysOld = (datetimeString) => {
  if (!datetimeString) return;

  const datetime = new Date(datetimeString);

  const today = new Date();
  const differenceInMilliseconds = today.getTime() - datetime.getTime();
  const differenceInDays = Math.floor(
    differenceInMilliseconds / (1000 * 60 * 60 * 24)
  );
  return `${differenceInDays} days ago`;
};

export const calculateTimeframe = ({ startDate, endDate }) => {
  if (!startDate || !endDate) return;
  try {
    const isSameDay =
      startDate.getDate() === endDate.getDate() &&
      startDate.getMonth() === endDate.getMonth() &&
      startDate.getFullYear() === endDate.getFullYear();
    if (isSameDay) {
      return "hourly";
    }

    const isSameMonth =
      startDate.getMonth() === endDate.getMonth() &&
      startDate.getFullYear() === endDate.getFullYear();
    if (isSameMonth) {
      return "daily";
    }
    return "monthly";
  } catch (e) {
    return;
  }
};

export const findMinMaxDate = (data, dateKey) => {
  let minDate = null;
  let maxDate = null;
  if (!data) return { minDate, maxDate };

  const dates = data?.map((item) => new Date(item[dateKey]));
  if (dates.length === 0) return { minDate, maxDate };

  minDate = new Date(Math.min.apply(null, dates));
  maxDate = new Date(Math.max.apply(null, dates));

  // const minDateString = minDate.toISOString().split("T")[0];
  // const maxDateString = maxDate.toISOString().split("T")[0];
  // return { minDate: minDateString, maxDate: maxDateString };
  return { minDate, maxDate };
};

export const getWeekdayNumber = (weekdayString) => {
  const mapping = {
    Sun: 0,
    Mon: 1,
    Tue: 2,
    Wed: 3,
    Thu: 4,
    Fri: 5,
    Sat: 6,
  };
  return mapping[weekdayString];
};

export const getWeekdayString = (weekdayInteger) => {
  const mapping = {
    0: "Sun",
    1: "Mon",
    2: "Tue",
    3: "Wed",
    4: "Thu",
    5: "Fri",
    6: "Sat",
  };
  return mapping[weekdayInteger];
};

export const getMonthString = (monthNumber) => {
  const mapping = {
    0: "Jan",
    1: "Feb",
    2: "Mar",
    3: "Apr",
    4: "May",
    5: "Jun",
    6: "Jul",
    7: "Aug",
    8: "Sep",
    9: "Oct",
    10: "Nov",
    11: "Dec",
  };
  return mapping[monthNumber];
};

export const dateToUCTDatetime = (date) =>
  new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    0,
    0,
    0
  );

export const chartTypes = Object.freeze({
    BAR: "bar",
    LINE: "line",
    AREA: "area",
    SCATTER: "scatter",
    COMPOSED: "composed",
    HISTOGRAM: "histogram",
    DOUGHNUT: "doughnut",
    PIE: "pie",
    KPI: "kpi",
    HEATMAP: "heatmap",
    MINI_MAP: 'miniMap'
});


export const diagramTypes = Object.freeze({
    BAR: "bar",
    LINE: "line",
    SCATTER: "scatter",
});

import { distinctFilter } from "../utils/dataManipulation";
import { sentenceCase } from "../utils/namesManipulation";

export const extractLocations = (allBuildings, nullAlternative) => {
  if (!allBuildings) return allBuildings;
  const dataLocations = allBuildings
    ?.map((building) => {
      if (!building?.location) return nullAlternative;
      return sentenceCase(building?.location);
    })
    ?.filter(distinctFilter);
  return dataLocations;
};

export const extractCertifications = (allBuildings, nullAlternative) => {
  if (!allBuildings) return allBuildings;
  const dataCertificates = allBuildings
    ?.reduce((total, current) => {
      const certificates = current?.certificate
        ?.filter((cert) => cert.type !== "EU EPC")
        ?.map((cert) => cert.type);
      if (!certificates || certificates.length === 0)
        total.push(nullAlternative);
      else {
        total.push(...(certificates || []));
      }
      return total;
    }, [])
    .filter(distinctFilter);
  return dataCertificates;
};

import { settingsOptions } from "../utils/dynamicDashboard";
import { chartTypes } from "./dynamicDashboard";

export const FiltersNames = Object.freeze({
    SELECTED_SENSORS: 'selectedUtilities',
    SELECTED_SCENARIOS: 'selectedScenarios',
    TIMEFRAME: 'timeframe',
    SELECTED_DAYS_OF_WEEK: 'selectedDaysOfWeek',
    SELECTED_HOURS_OF_DAY: 'selectedHoursOfDay',
    DATE_RANGE: 'dateRange',
    START_DATE: 'startDate',
    END_DATE: 'endDate',
    DIAGRAMS: 'diagrams',
    X_AXIS: 'xAxis',
    Y_AXIS: 'yAxis',
    DATAPOINT: 'dataPoint',
    TITLE: 'title',
    SORT_VALUE: 'sortValue',
    LEGEND_POSITION: 'legendsPosition',

    DIAGRAMS_COLOR: 'color',
    DIAGRAMS_DIRECTION: 'direction',
    DIAGRAMS_STACKS: 'stacks',
    DIAGRAMS_PARTITIONS: 'partitions',
    DIAGRAMS_DIAGRAM_TYPE: 'diagramType',
    DIAGRAMS_DIAGRAM_DATA_KEY: 'dataKey',
})



const relevantConfigOptions = {
    [chartTypes.AREA]: [
        settingsOptions.X,
        settingsOptions.PARTITIONS,
        settingsOptions.HEIGHT_100,
        settingsOptions.DIRECTION,
        settingsOptions.LEGENDS_POSITION
    ],
    [chartTypes.BAR]: [
        settingsOptions.X,
        settingsOptions.PARTITIONS,
        settingsOptions.STACKS,
        settingsOptions.DIRECTION,
        settingsOptions.LEGENDS_POSITION
    ],
    [chartTypes.PIE]: [settingsOptions.PARTITIONS, settingsOptions.STACKS,
    settingsOptions.LEGENDS_POSITION

    ],
    [chartTypes.DOUGHNUT]: [settingsOptions.PARTITIONS, settingsOptions.STACKS,
    settingsOptions.LEGENDS_POSITION

    ],
    [chartTypes.LINE]: [
        settingsOptions.X,
        settingsOptions.PARTITIONS,
        settingsOptions.SORT_BY,
        settingsOptions.DIRECTION,
        settingsOptions.LEGENDS_POSITION
    ],
    [chartTypes.COMPOSED]: [
        settingsOptions.X,
        settingsOptions.QUANTITY,
        settingsOptions.DIAGRAM_TYPE,
        settingsOptions.COLOR,
        settingsOptions.LEGENDS_POSITION
    ],
    [chartTypes.KPI]: [settingsOptions.PARTITIONS, settingsOptions.STACKS, settingsOptions.DIRECTION],
    [chartTypes.HISTOGRAM]: [settingsOptions.QUANTITY, settingsOptions.COLOR,
    settingsOptions.LEGENDS_POSITION

    ],
    [chartTypes.HEATMAP]: [settingsOptions.X, settingsOptions.Y, settingsOptions.COLOR],
};




export const isRelevantOption = (chartType, option, index) => {
    if (
        index > 0 &&
        chartType !== chartTypes.HISTOGRAM &&
        chartType !== chartTypes.COMPOSED
    )
        return false;
    return relevantConfigOptions[chartType]?.includes(option);
};

import { useRef, useState } from "react";
import { chartTypes } from "../enums/dynamicDashboard";


export const settingsOptions = Object.freeze({
    X: "x",
    Y: "y",
    QUANTITY: "quantity",
    DIAGRAM_TYPE: "diagramType",
    PARTITIONS: "partitions",
    STACKS: "stacks",
    HEIGHT_100: "height100",
    DIRECTION: "direction",
    COLOR: "color",
    SORT_BY: "sortBy",
    LEGENDS_POSITION: "legendsPosition",
    DATA_POINT: 'dataPoint',
    TITLE: 'title'
});


export const getFlattenData = ({ data, sensors, valueName }) => {
    const flattenData = [];
    for (const row of data) {
        const rowCopy = { ...row };
        for (const col of sensors) delete rowCopy[col];
        for (const key of Object.keys(row)) {
            if (sensors?.includes(key)) {
                const flatItem = {
                    ...rowCopy,
                    [valueName]: row[key],
                    sensor: key,
                };
                flattenData.push(flatItem);
            }
        }
    }
    return flattenData;
};

export const aggregatedSumValue = ({ data, groupByColumns, aggregateColumns }) => {
    if (!groupByColumns?.filter((e) => e !== null && e !== undefined)?.length) {
        return [
            data?.reduce((acc, cur) => {
                aggregateColumns.forEach((col) => {
                    acc[col] = (acc[col] || 0) + cur[col];
                });
                return acc;
            }, {}),
        ];
    }

    return Object.values(
        data?.reduce((acc, cur) => {
            const key = groupByColumns?.map((col) => cur[col]).join("|");
            if (!acc[key]) {
                acc[key] = Object.fromEntries(
                    groupByColumns?.map((col) => [col, cur[col]])
                );
                aggregateColumns.forEach((col) => {
                    acc[key][col] = cur[col];
                });
            } else {
                aggregateColumns.forEach((col) => {
                    acc[key][col] += cur[col];
                });
            }
            return acc;
        }, {})
    );
};

export const pivotData = (data, pivotColumn, valueColumn) => {
    if (!data?.length || !pivotColumn) return [];
    const pivotedData = {};
    const keyColumns = Object.keys(data[0]).filter(
        (col) => col !== pivotColumn && col !== valueColumn
    );
    data?.forEach((entry) => {
        const key = keyColumns?.map((col) => entry[col]).join("-");
        if (!pivotedData[key]) {
            pivotedData[key] = {};
            keyColumns?.forEach((col) => {
                pivotedData[key][col] = entry[col];
            });
        }
        pivotedData[key][entry[pivotColumn]] = entry[valueColumn];
    });
    return Object.values(pivotedData);
};


export const useDynamicDashboard = ({ defaultChart, defaultDashboardConfigs, chartsConfigs }) => {
    const [allChartsConfigs, setAllChartsConfigs] = useState(chartsConfigs);
    const scrollRef = useRef();

    const addChart = () => {
        const maxY = allChartsConfigs?.length
            ? Math.max(...allChartsConfigs?.map((l) => l.y + l.h))
            : -1;
        const maxId = allChartsConfigs?.length
            ? Math.max(...allChartsConfigs?.map((l) => l.id))
            : -1;
        const newChart = {
            ...defaultChart,
            y: maxY + 1,
            id: maxId + 1,
        };

        setAllChartsConfigs([...allChartsConfigs, newChart]);
        scrollRef.current?.scrollIntoView({
            behavior: "smooth",
        });
    };

    const removeChart = (id) => {
        setAllChartsConfigs((allChartsConfigs) =>
            allChartsConfigs.filter((l) => l.id !== id)
        );
    };

    const updateChart = (id, key, value) => {
        setAllChartsConfigs((allChartsConfigs) => {
            const updatedCharts = allChartsConfigs.map((l) => {
                if (l.id !== id) return l;
                else return { ...l, [key]: value };
            });
            return updatedCharts;
        });
    };

    const resetDashboardConfigs = () => {
        setAllChartsConfigs(defaultDashboardConfigs);
    };

    return { allChartsConfigs, setAllChartsConfigs, updateChart, removeChart, resetDashboardConfigs, addChart, scrollRef }
}


export const useDynamicDashboardMultiTab = ({ defaultChart, defaultDashboardConfigs, allTabschartsConfigs }) => {
    const [allTabsAllChartsConfigs, setAllTabsAllChartsConfigs] = useState(allTabschartsConfigs);
    const scrollRef = useRef();

    const tabAddChart = (tabIndex) => () => {
        const tabAllChartsConfigs = allTabsAllChartsConfigs[tabIndex]
        const maxY = tabAllChartsConfigs?.length
            ? Math.max(...tabAllChartsConfigs?.map((l) => l.y + l.h))
            : -1;
        const maxId = tabAllChartsConfigs?.length
            ? Math.max(...tabAllChartsConfigs?.map((l) => l.id))
            : -1;
        const newChart = {
            ...defaultChart,
            y: maxY + 1,
            id: maxId + 1,
        };

        const newAllTabsChartsConfigs = allTabsAllChartsConfigs.map((item, index) => {
            if (index !== tabIndex) return item
            return [...item, newChart]
        })

        setAllTabsAllChartsConfigs(newAllTabsChartsConfigs);
        scrollRef.current?.scrollIntoView({
            behavior: "smooth",
        });
    };

    const tabRemoveChart = (tabIndex) => (id) => {

        setAllTabsAllChartsConfigs((allTabsAllChartsConfigs) => {
            const filteredAllTabsChartsConfigs = allTabsAllChartsConfigs.map((item, index) => {
                if (index !== tabIndex) return item
                return item.filter((l) => l.id !== id)
            })
            return filteredAllTabsChartsConfigs
        });
    };

    const tabUpdateChart = (tabIndex) => (id, key, value) => {
        setAllTabsAllChartsConfigs((allTabsAllChartsConfigs) => {
            const updatedAllTabsChartsConfigs = allTabsAllChartsConfigs.map((item, index) => {
                if (index !== tabIndex) return item
                const updatedCharts = item.map((l) => {
                    if (l.id !== id) return l;
                    else return { ...l, [key]: value };
                });
                return updatedCharts;
            })
        });
    };

    const resetDashboardConfigs = () => {
        setAllTabsAllChartsConfigs(defaultDashboardConfigs);
    };

    return { allTabsAllChartsConfigs, setAllTabsAllChartsConfigs, tabUpdateChart, tabRemoveChart, resetDashboardConfigs, tabAddChart, scrollRef }
}


export const getMapPointColor = ({ value, min, max, colorScale }) => {
    value = Math.max(min, Math.min(value, max));

    const ratio = min === max ? 1 : (value - min) / (max - min);
    if (colorScale) {
        const scaled = colorScale(ratio);
        const rgb = scaled._rgb;

        const r = Math.round(255 * (1 - ratio));
        const g = Math.round(255 * ratio);
        const b = 0;

        return `rgb(${rgb[0]},${rgb[1]},${rgb[2]})`;
    } else return 'rgb(0,0,0)'
}


export const getAxisNameWithUnit = ({ dataKey, unit }) => {
    return `${dataKey ? dataKey : ''} ${unit ? "(" + unit + ")" : ''}`
}
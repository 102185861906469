import React from "react";
import { CircularProgress } from "@mui/material";
export const Loading = ({ style }) => {
  return (
    <div
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)",
        zIndex: "99",
        ...style,
      }}
    >
      <CircularProgress />
    </div>
  );
};

import React, { useEffect, useState } from "react";
import { CustomModal } from "../CustomModal";
import { AssetCard } from "./AssetCard";
import Draggable from "react-draggable";
import { Stack } from "@mui/material";
import { Icon } from "../Icon";

export const AssetCardModal = ({ building, triggerFlag, onCardClick }) => {
    const [modalOpen, setModalOpen] = useState(false)

    useEffect(() => {
        if (building?.id) setModalOpen(true)
    }, [building?.id, triggerFlag])

    return (
        <Draggable>
            <CustomModal
                modalOpen={modalOpen}
                setModalOpen={setModalOpen}
                onClose={undefined}
                width={400}
                height={0}
                closeIcon={false}
                padding={0}
                style={{
                    top: '50%',
                    right: 0,
                    left: 'unset',
                    transform: 'translateY(-50%)',
                    height: 'fit-content',
                    marginRight: '1.6rem'
                }}
                slotProps={{
                    backdrop: {
                        sx: {
                            backgroundColor: 'rgba(0,0,0,0.05)',
                        }
                    }
                }}
                disableScrollLock={true}
            >
                <Stack flexDirection={'row-reverse'} className="bg-blue-50 h-6 pr-2 cursor-grab">
                    <Icon iconName={'Close'} size={'sm'} color={'var(--clr-secondary-blue-500)'}
                        onClick={() => setModalOpen(false)}
                    />
                </Stack>
                <AssetCard
                    imgPath={building?.image_url_value}
                    name={building?.descriptive_name}
                    city={building?.address}
                    type={building?.type}
                    year={building?.construction_year}
                    lastModernized={building?.last_modernized}
                    energyClass={building?.energy_label}
                    value={building?.cash_in}
                    emission={building?.carbon_dioxide_emission_value}
                    climatePositive={building?.climate_positive}
                    key={building?.id}
                    certificateList={building?.certificate}
                    onClick={onCardClick}
                />

            </CustomModal>
        </Draggable>
    )
}

import { useState, useRef, useEffect } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import styled from "styled-components";

export const DropdownSingleSelectNative = ({
  className,
  style,
  label,
  placeholder,
  options,
  displayOptions,
  selectedValue,
  setSelectedValue,
  onChange,
}) => {
  if (!displayOptions) displayOptions = options;

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedValue(value);
    if (onChange) onChange(event);
  };

  return (
    <div className={`w-full ${className}`} style={style}>
      <label for="a">{label}</label>

      <select
        style={{
          background: "transparent",
          border: "none",
          width: "100%",
          cursor: "pointer",
        }}
        onChange={handleChange}
        name="a"
        id="a"
        value={selectedValue}
      >
        {placeholder && (
          <option value="" selected hidden>
            {placeholder}
          </option>
        )}
        {selectedValue && (
          <option value={selectedValue} disabled hidden>
            {selectedValue}
          </option>
        )}
        {options.map((option, index) => {
          return (
            <option
              style={{
                cursor: "pointer",
              }}
              value={options[index]}
            >
              {displayOptions[index]}
            </option>
          );
        })}
      </select>
    </div>
  );
};
const Wrapper = styled("div")(() => ({
  "& .MuiSelect-select:focus": {
    backgroundColor: "transparent",
  },

  "& .MuiInputLabel-shrink": {
    transform: "translate(1.2rem, 0.7rem) scale(0.75)",
    fontFamily: "Nunito Sans",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "1.2rem",
    letterSpacing: "0.1em",
    color: "var(--clr-gray-600)",
  },
}));

import { useState, useRef, useEffect } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import styled from "styled-components";
import { Box, IconButton, ListSubheader, Stack } from "@mui/material";
import { Icon } from "./Icon";

const ChevronDownAndUp = ({ isOpen, ...props }) => {
  return (
    <svg
      {...props}
      width="11"
      height="6"
      viewBox="0 0 11 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        transform: isOpen ? "rotate(180deg)" : "none",
        position: "absolute",
        right: "1.6rem",
        cursor: "pointer",
        top: "calc(50% - 0.3rem)",
      }}
    >
      <path
        d="M1 0.750002L5.5 5.25L10 0.75"
        stroke="#1C1D20"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
    </svg>
  );
};

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: 2,
  width: 16,
  height: 16,

  backgroundColor: "var(--clr-white)",
  border: "1px solid var(--clr-gray-700)",
  "input:hover ~ &": {
    border: "1px solid var(--clr-secondary-blue-300)",
    boxShadow:
      "0px 0.2px 0.6px rgba(36, 89, 234, 0.041), 0px 0.5px 1.8px rgba(36, 89, 234, 0.059), 0px 1.2px 4.2px rgba(36, 89, 234, 0.077), 0px 4px 14px rgba(36, 89, 234, 0.11)",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "var(--clr-white)",
  border: "1px solid var(--clr-secondary-blue-500)",
  "&:before": {
    display: "block",
    position: "absolute",
    right: "8px",
    top: "4px",
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='blue' /%3E%3C/svg%3E\")",
    content: '""',
  },
  "input:hover ~ &": {
    border: "1px solid var(--clr-secondary-blue-300)",
  },
});

const MenuItemComponent = ({ key, value, selected, displayOption, }) => {
  return <MenuItem
    key={key}
    value={value}
    selected={selected}
    sx={{
      backgroundColor: "var(--clr-white)",
      marginRight: "4px",
      marginLeft: "4px",
      padding: "0.3rem 1.5rem",
      "&:hover": {
        backgroundColor: "var(--clr-secondary-blue-50)",
      },
      "&:focus:not(:hover)": {
        backgroundColor: "white",
      },
    }}
  >
    <ListItemText
      primary={displayOption}
      disableTypography
      sx={{
        color: "var(--clr-gray-900)",
        fontFamily: "Nunito Sans",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "1.4rem",
        lineHeight: "1.9rem",
        marginLeft: "0.3rem",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
      }}
    />
  </MenuItem>
}


export const DropdownSingleSelect = ({
  className,
  label,
  options,
  displayOptions,
  optionsGroups,
  selectedValue,
  setSelectedValue,
  onChange,
  width,
  ...props
}) => {
  const isGrouped = optionsGroups?.length
  if (!isGrouped)
    optionsGroups = [{ options }]

  if (!displayOptions) displayOptions = options;

  const [isOpen, setIsOpen] = useState(false);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedValue(value);
    if (onChange) onChange(event);
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const ref = useRef(null);


  const [openGroups, setOpenGroups] = useState({});
  const toggleGroup = (group) => {
    setOpenGroups((prev) => ({ ...prev, [group]: !prev[group] }));
  };

  return (
    <Wrapper
      style={{ width: width, position: "relative" }}
      className={className}
      ref={ref}
      {...props}
    >
      <FormControl variant="filled" sx={{ width: "100%" }}>
        <InputLabel
          sx={{
            fontFamily: "Nunito Sans",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "1.4rem",
            lineHeight: "1.9rem",
            transform: "translate(1.2rem, 1rem) scale(1)",
            color: "var(--clr-gray-600)",
          }}
        >
          {label}
        </InputLabel>
        <Select
          value={selectedValue}
          onChange={handleChange}
          onClose={handleClose}
          onOpen={handleOpen}
          renderValue={(selected) => selected}
          MenuProps={{
            sx: {
              "&& .Mui-selected:not(:hover)": {
                backgroundColor: "var(--clr-secondary-blue-50)",
              },
              "& .MuiPaper-root": {
                width: `${ref.current ? ref.current.offsetWidth : ""
                  }px !important`,
                padding: "0rem",
                borderRadius: "4px",
              },
            },
          }}
          IconComponent={(props) => (
            <ChevronDownAndUp isOpen={isOpen} {...props} />
          )}
          sx={{
            backgroundColor: "var(--clr-white)",
            height: "4rem",
            border: `1px solid ${selectedValue === null
              ? "var(--clr-gray-900)"
              : "var(--clr-leaftech-blue-500)"
              }`,
            borderRadius: "7px",
            fontFamily: "Nunito Sans",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "1.4rem",
            lineHeight: "1.9rem",
            "&:hover": {
              backgroundColor: "var(--clr-white)",
              border: "1px solid var(--clr-secondary-blue-200)",
              ":before": {
                border: "none !important",
              },
            },
            "&.Mui-focused": {
              border: "1px solid var(--clr-secondary-blue-500)",
              backgroundColor: "white",
            },
            "&:before": {
              border: "none",
            },
            "&:after": {
              border: "none",
            },
          }}
        >
          {optionsGroups.map((group) => {
            const groupOptions = options?.filter(name => group?.options?.includes(name))
            const groupDisplayOptions = displayOptions.filter((_, index) => groupOptions.includes(options[index]))
            const OptionsComponent = groupOptions?.map((name, index) => {
              return MenuItemComponent({ key: name + group, value: name, selected: name === selectedValue, displayOption: groupDisplayOptions[index] })
            })

            if (isGrouped)
              return [
                <ListSubheader className="w-full p-1">
                  <Box
                    className={`${openGroups[group.group] ? 'bg-blue-100' : 'bg-gray-100'} hover:bg-blue-200 cursor-pointer px-2 w-full flex items-center justify-between`}
                    onClick={() => toggleGroup(group.group)}
                  >
                    <span className="t-heading-s-700">
                      {group.group}
                    </span>
                    <IconButton size="small">
                      {openGroups[group.group] ? <Icon iconName={'ChevronDown'} /> : <Icon iconName={'ChevronRight'} />}
                    </IconButton>
                  </Box>
                </ListSubheader>,
                openGroups[group.group] && OptionsComponent
              ]

            return OptionsComponent

          })}

        </Select>
      </FormControl>
    </Wrapper>
  );
};

export const DropdownPure0 = ({
  className,
  placeholder,
  options,
  displayOptions,
  selectedValue,
  setSelectedValue,
  multiple,
}) => {
  const handleChange = (e) => {
    setSelectedValue(e.target.value);
  };
  console.log(selectedValue);
  if (!displayOptions) displayOptions = options;
  return (
    <div className={`relative  ${className}`}>
      <select
        value={selectedValue}
        onChange={handleChange}
        style={{
          backgroundColor: "var(--clr-white)",
          border: `1px solid ${selectedValue === null
            ? "var(--clr-gray-900)"
            : "var(--clr-leaftech-blue-500)"
            }`,
          borderRadius: "7px",
          fontFamily: "Nunito Sans",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "1.4rem",
          lineHeight: "1.9rem",
        }}
        multiple={multiple}
        className="w-full h-full appearance-none bg-white border border-gray-300 rounded-md py-2 pl-4 pr-10 shadow-sm text-sm focus:outline-none focus:border-blue-500"
      >
        {selectedValue && (
          <option value={selectedValue} disabled hidden>
            {/* {multiple ? selectedValue.join(", ") : selectedValue} */}
            {selectedValue}
          </option>
        )}
        {!selectedValue && (
          <option disabled selected hidden>
            {placeholder}
          </option>
        )}
        {options.map((option, index) => (
          <option
            key={option}
            value={option}
            style={{
              color: "var(--clr-gray-900)",
              fontFamily: "Nunito Sans",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "1.4rem",
              lineHeight: "1.9rem",
              marginLeft: "0.3rem",
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            {displayOptions[index]}
          </option>
        ))}
      </select>
      <div className="absolute inset-y-0 right-4 flex items-center pointer-events-none">
        <ChevronDownAndUp isOpen={false} />
      </div>
    </div>
  );
};

export const DropdownPure = ({
  className,
  placeholder,
  options,
  displayOptions,
  selectedValue,
  setSelectedValue,
  multiple,
  onChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  if (!displayOptions) displayOptions = options;

  const toggleOption = (option) => {
    if (!multiple) {
      setSelectedValue(option);
      setIsOpen(false);
    } else {
      if (option === "all") {
        setSelectedValue(
          selectedValue?.length >= options?.length ? [] : options
        );
      } else if (selectedValue?.includes(option)) {
        setSelectedValue(selectedValue.filter((item) => item !== option));
      } else {
        setSelectedValue([...selectedValue, option]);
      }
    }
    if (onChange) onChange();
  };

  const dropdownRef = useRef(null);

  const handleOutsideClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const clear = () => {
    if (multiple) setSelectedValue([]);
    if (!multiple) setSelectedValue(null);
  };
  return (
    <div
      ref={dropdownRef}
      className={`relative inline-block cursor-pointer ${className}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div>
        <button
          type="button"
          className="inline-flex cursor-pointer  justify-between w-full rounded-md hover:border-blue-700 px-4 py-2  transition ease-in-out duration-150"
          onClick={() => setIsOpen(!isOpen)}
          style={{
            backgroundColor: "var(--clr-white)",
            height: "4rem",
            border: `1px solid ${selectedValue === null
              ? "var(--clr-gray-900)"
              : "var(--clr-leaftech-blue-500)"
              }`,
            borderRadius: "7px",
            fontFamily: "Nunito Sans",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "1.4rem",
            lineHeight: "1.9rem",
          }}
        >
          {multiple ? (
            <span
              className={`block truncate ${selectedValue?.length === 0 && "text-gray-500"
                }`}
            >
              {selectedValue?.length === options?.length
                ? "All"
                : selectedValue?.length === 0
                  ? placeholder || "Select Options"
                  : selectedValue?.join(", ")}
            </span>
          ) : (
            <span
              className={`block truncate ${!selectedValue && "text-gray-500"}`}
            >
              {!selectedValue ? placeholder || "Select Options" : selectedValue}
            </span>
          )}
          <Stack className="items-center h-full">
            {((multiple && selectedValue?.length) ||
              (!multiple && selectedValue)) && (isHovered) && (
                <Icon
                  className="mr-6 w-[10px] h-[10px]"
                  svgClassName=""
                  iconName={"Close"}
                  onClick={(e) => {
                    e.stopPropagation();
                    clear();
                  }}
                />
              )}
            <ChevronDownAndUp isOpen={false} />
          </Stack>
        </button>
      </div>

      {isOpen && (
        <div className="w-full overflow-auto absolute z-[10] p-2 right-0 mt-2 rounded-md border border-solid border-gray-300 bg-white shadow-md"
        >
          <div className="py-1 ">
            {multiple && (
              <Stack
                gap={2}
                key={"all"}
                className={`${selectedValue?.length === options?.length ? "bg-blue-100" : ""
                  } w-full cursor-pointer hover:bg-blue-50 my-[1px] py-1 `}
                onClick={() => toggleOption("all")}
                role="option"
                style={{
                  color: "var(--clr-gray-900)",
                  fontFamily: "Nunito Sans",
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "1.4rem",
                  lineHeight: "1.9rem",
                  marginLeft: "0.3rem",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                <Checkbox
                  checked={
                    selectedValue?.length > 0 &&
                    selectedValue?.length >= options?.length
                  }
                  checkedIcon={<BpCheckedIcon />}
                  icon={<BpIcon />}
                />
                <span>{"All"}</span>
              </Stack>
            )}

            {options.map((option, index) => (
              <Stack
                gap={2}
                key={option}
                className={`${(multiple && selectedValue?.includes(option)) ||
                  selectedValue === option
                  ? "bg-blue-100"
                  : ""
                  } w-full cursor-pointer hover:bg-blue-50 py-1 my-[1px]`}
                onClick={() => toggleOption(option)}
                role="option"
                style={{
                  color: "var(--clr-gray-900)",
                  fontFamily: "Nunito Sans",
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "1.4rem",
                  lineHeight: "1.9rem",
                  marginLeft: "0.3rem",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                {multiple && (
                  <Checkbox
                    checkedIcon={<BpCheckedIcon />}
                    icon={<BpIcon />}
                    checked={selectedValue?.includes(option)}
                  />
                )}
                <span>{displayOptions[index]}</span>
              </Stack>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const Wrapper = styled("div")(() => ({
  "& .MuiSelect-select:focus": {
    backgroundColor: "transparent",
  },

  "& .MuiInputLabel-shrink": {
    transform: "translate(1.2rem, 0.7rem) scale(0.75)",
    fontFamily: "Nunito Sans",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "1.2rem",
    letterSpacing: "0.1em",
    color: "var(--clr-gray-600)",
  },
}));

import { customApiCall } from "../utils/axios";
import apiUrls from "../api/urls";
import { CertificateCard, EmptyCertificateCard } from "./CertificateCard";
import { Loading } from "./Loading";
import { ButtonNew } from "./ButtonNew";
import { Icon } from "./Icon";
import { Stack, useMediaQuery, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useCheckCertificatesDownloadFilesStatus } from "../api/hooks/allHooks";
import {
  LoadingOrEmptyComponent,
  LoadingOrEmptyWrapper,
} from "./LoadingAndEmptyHandler";
import { ComponentsCommonHeader } from "./ComponentsCommonHeader";
import { useParams } from "react-router-dom";
import { eventNames, withGAEventTracking } from "../functions/googleAnalytics";
import { getOldProjectId } from "../utils/hardcoding";

const getLabel = (projectId) => {
  if (projectId == 19) return "09.12.2009";
  if (projectId == 20) return "01.04.2009";
  if (projectId == 13) return "06.03.2017";
};

const EmptyState = ({ certificateType }) => {
  const { t } = useTranslation();
  const { projectId: newProjectId } = useParams();
  const projectId = getOldProjectId(Number(newProjectId))
  const newProject =
    Number(projectId) == 13 ||
    Number(projectId) == 19 ||
    Number(projectId) == 20;
  return (
    <Grid item xs={12} xl2={6}>
      {certificateType === "epc" ? (
        <EmptyCertificateCard
          label={
            !newProject
              ? "EPC missing!"
              : `EPC available - Issued: ${getLabel(projectId)}`
          }
        />
      ) : (
        <EmptyCertificateCard label={t("Certificates.cards.noCertificate")} />
      )}
    </Grid>
  );
};

export const CertificatesCardsArticle = ({
  projectId,
  className,
  certificates,
  isLoading,
  title,
  certificateType,
  buttonLongText,
  buttonShortText,
  hasButton,
  xs = 12,
  xl2 = 6,
}) => {
  const { t } = useTranslation();
  const isScreenLg = useMediaQuery((theme) => theme.breakpoints.down("lg"));

  const downloadCertificateFiles = ({ projectId, certificateType }) => {
    const url = apiUrls.files.certificates.download(projectId, certificateType);
    const fileName = certificateType + ".zip";
    const errorMessage = t("general.downloadFileProblem");
    customApiCall.download({ url, fileName, errorMessage });
  };

  const { data } = useCheckCertificatesDownloadFilesStatus(
    projectId,
    certificateType
  );
  const noData = !(certificates?.length > 0);
  return (
    <article className={`relative ${className}`}>
      {/* Header */}
      <ComponentsCommonHeader
        action={() => withGAEventTracking(eventNames.BP_DOWNLOAD_CERTIFICATE, { certificateType },
          () => downloadCertificateFiles({ projectId, certificateType }))}
        actionIsDisabled={data?.status !== 200}
        actionText={isScreenLg ? buttonShortText : buttonLongText}
        hasActionAccess={hasButton}
        title={title}
      />

      {/* Cards */}
      <LoadingOrEmptyWrapper
        showLoading={isLoading}
        showEmpty={noData}
        emptyComponent={() => <EmptyState certificateType={certificateType} />}
      >
        <Grid container spacing={4}>
          {certificates?.map((certificate, index) => {
            return (
              <Grid key={index} item xs={xs} xl2={xl2}>
                <CertificateCard
                  certificate={certificate}
                  type={certificateType}
                />
              </Grid>
            );
          })}
        </Grid>
      </LoadingOrEmptyWrapper>
    </article>
  );
};

import { updateUserLanguage } from "../api/functions/all";

export const valueOrAlternative = (value, alt = "---") => {
  return value ? value : alt;
};

export const replaceDecimalPointWithCommaOld = (number) => {
  if (!number) return null;
  const str = number.toString();
  return str.replace(".", ",");
};

export const replaceDecimalPointWithComma = (number) => {
  if (!number) return "";
  return number;
};

export const addCommaToNumber = (number) => {
  if (number === null || number === undefined || typeof number !== "number")
    return number;
  return number?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
};

export const formatNumberToGerman = (number) => {
  if (number === null || number === undefined || typeof number !== "number")
    return number;
  return number?.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
}

export const formatNumberBasedOnUser = (number) => {
  const userLanguage = localStorage.getItem('language')
  if (userLanguage == 'de')
    return formatNumberToGerman(number)
  else return addCommaToNumber(number)
}

export const sentenceCase = (string) => {
  if (!string) return null;
  return string == null
    ? null
    : string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

export const capitalizeFirstLetter = (string) => {
  if (!string) return null;
  return string == null
    ? null
    : string.charAt(0).toUpperCase() + string.slice(1);
};

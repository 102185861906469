// TODO:
// 1-consider responsiveness when user changes font size in browser settings.

import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useGetAllBuildings } from "../api/hooks/allHooks";
import { CardsContainer, FilterContainer } from "../components/AssetOverview";
import { setCurrentPageName } from "../features/routeName/routeNameSlice";
import {
  extractCertifications,
  extractLocations,
} from "../functions/AssetOverview";
import {
  filterDataBySearchTerm,
  filterDataBySinlgeValueFilter,
  filterDataByYear,
} from "../utils/dataManipulation";
import { eventNames, trackGAEvent } from "../functions/googleAnalytics";

const nullAlternative = "Pending";
export const AssetOverview = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setCurrentPageName("asset overview"));
    trackGAEvent(eventNames.AO_VIEW)
  }, []);

  const { filters, searchTerm } = useSelector((store) => store.allBuildings);
  const { user } = useSelector((store) => store.user);

  const { data, isLoading } = useGetAllBuildings(user?.user_id);
  const allBuildings = data || [];

  const dataLocations = extractLocations(allBuildings, nullAlternative);
  const dataCertificates = extractCertifications(allBuildings, nullAlternative);

  const filterCards = () => {
    let filteredBuildings;
    filteredBuildings = filterDataBySearchTerm(
      allBuildings,
      searchTerm,
      "name"
    );
    for (const filterName in filters) {
      const filterValues = filters[filterName].values;
      const lookUpField = filters[filterName].lookUpField;
      const nestedLookUpField = filters[filterName].nestedLookUpField;
      if (filterName === "year")
        filteredBuildings = filterDataByYear(
          filteredBuildings,
          filterValues,
          filters[filterName].options,
          lookUpField,
          nullAlternative
        );
      else {
        filteredBuildings = filterDataBySinlgeValueFilter(
          filteredBuildings,
          filterValues,
          lookUpField,
          nestedLookUpField,
          nullAlternative
        );
      }
    }
    return filteredBuildings;
  };
  const filteredBuildings = filterCards();

  return (
    <div>
      <Helmet>
        <title>Asset overview | Leaftech</title>
      </Helmet>
      <h1 className="t-heading-xxl text-gray-800 sentence-case">
        {t("AssetOverview.title")}
      </h1>
      <FilterContainer
        noOfCards={filteredBuildings?.length}
        locationsOptions={dataLocations}
        certificatesOptions={dataCertificates}
      />

      <CardsContainer
        className="mt-3"
        buildings={filteredBuildings}
        isLoading={isLoading}
      />
    </div>
  );
};

// TODO:
// 1-Replace ImageWrapper with suitable tailwind classes.

import { Card, Stack } from "@mui/material";
import styled from "styled-components";
import { theme } from "../../styles/theme";
import {
  replaceDecimalPointWithComma,
  valueOrAlternative,
} from "../../utils/namesManipulation";
import { EnergyLabel } from "../EnergyLabel";
import { Icon } from "../Icon";
import { useTranslation } from "react-i18next";
import { getAletrnativeImage } from "../../utils/buildingImages";

const CardImageLabel = ({ label, className }) => {
  return (
    <ImageLabelWrapper className={className}>
      <Icon iconName="Tree" color="var(--clr-secondary-blue-600)"></Icon>
      <span className="text-blue-500 t-numbers-xxs">{label}</span>
    </ImageLabelWrapper>
  );
};

export const AssetCard = ({
  className,
  imgPath,
  name,
  city,
  type,
  year,
  lastModernized,
  value,
  emission,
  onClick,
  climatePositive,
  certificateList,
}) => {
  const { t } = useTranslation();
  return (
    <article className={className}>
      <Card onClick={onClick}>
        {/* image-container */}
        <div className="relative">
          {/* image-label */}
          {climatePositive && (
            <CardImageLabel
              className="absolute top-2 right-2"
              label="Clima positive"
            />
          )}
          <img
            src={imgPath}
            className="h-48 rounded-md w-full"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = getAletrnativeImage(type);
            }}
          />
        </div>

        {/* header */}
        <h2 className="capitalize mt-4 ml-2 t-heading-l  ">
          {valueOrAlternative(name)}
        </h2>

        {/* content */}
        <Stack className="mt-3 mx-2 pb-2 justify-between">
          {/* Left column */}
          <ul className="w-2/5">
            {/* City */}
            <li className="info-item">
              <Stack spacing={theme.spacing(3)} className="mb-1.5">
                <Icon
                  color="var(--clr-gray-600)"
                  iconName={"LocationIcon"}
                  className="mr-1 ml-[1px]"
                ></Icon>
                <p className="sentence-case short-text t-body-s  ">
                  {valueOrAlternative(city)}
                </p>
              </Stack>
            </li>
            {/* Type */}
            <li className="info-item">
              <Stack spacing={theme.spacing(3)} className="mb-1.5">
                <Icon
                  color="var(--clr-gray-600)"
                  iconName={"BuildingTypeIcon"}
                ></Icon>
                <p className="sentence-case short-text t-body-s  ">
                  {t(valueOrAlternative(type))}
                </p>
              </Stack>
            </li>
            {/* Construction year */}
            <li className="info-item">
              <Stack spacing={theme.spacing(3)} className="mb-1.5">
                <Icon
                  color="var(--clr-gray-600)"
                  iconName={"ConstructionIcon"}
                ></Icon>
                <p className="sentence-case short-text t-body-s  ">
                  {valueOrAlternative(year)}{" "}
                  {lastModernized && `(${lastModernized})`}
                </p>
              </Stack>
            </li>
            {/* Value */}
            <li className="info-item">
              <Stack spacing={theme.spacing(3)}>
                <Icon color="var(--clr-gray-600)" iconName="Coins"></Icon>
                <p className="sentence-case short-text t-body-s  ">
                  {`${value ? value + "€" : valueOrAlternative("")}`}
                </p>
              </Stack>
            </li>
          </ul>

          {/* Right column */}
          <ul className="text-right">
            {/* Energy rating */}
            <li>
              <p className=" t-label-m-caps  text-gray-400 uppercase ">
                {t("AssetOverview.assetCard.epc")}
              </p>
              {certificateList?.length > 0 && (
                <Stack justifyContent="flex-end" spacing={theme.spacing(0.5)}>
                  {certificateList?.map((certificate, index) => {
                    if (certificate.type === "EU EPC")
                      return (
                        <EnergyLabel
                          key={index}
                          energyClass={certificate.label}
                          className="w-6 h-6"
                          size="sm"
                        />
                      );
                  })}
                </Stack>
              )}
              {certificateList?.length === 0 && (
                <p className="mt-1 mb-4 t-label-l text-black">
                  {t("AssetOverview.assetCard.pending")}
                </p>
              )}
            </li>
            {/* Emission */}
            <li className="mt-3">
              <p className=" t-label-m-caps  text-gray-400 ">
                {t("AssetOverview.assetCard.emission")}
              </p>
              {emission && (
                <p className="t-numbers-m  text-blue-500">
                  {replaceDecimalPointWithComma(emission)}
                  {" Kg CO₂/m²"}
                </p>
              )}
              {!emission && (
                <p className="short-text t-body-s  ">
                  {valueOrAlternative("")}{" "}
                </p>
              )}
            </li>
          </ul>
        </Stack>
      </Card>
    </article>
  );
};

const ImageLabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 6px;
  padding: 0.6rem 0.5rem 0.8rem 0.5rem;
  background: var(--clr-secondary-blue-100);
  border-radius: 3px;
  text-transform: capitalize;
`;

import { Stack } from "@mui/material";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";

export const NumericRangePicker = ({
    label,
    min,
    max,
    value,
    step,
    setValue,
    className,
    changeByTyping,
    style,
    ...props
}) => {
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const sliderValue = [value?.[0] ?? min, value?.[1] ?? max]
    const valueIsNotSelected = sliderValue[0] === min && sliderValue[1] === max

    return (
        <div className={`h-12 ${className}`}
            style={{
                backgroundColor: "var(--clr-white)",
                padding: "4px 12px",
                border: `1px solid ${valueIsNotSelected
                    ? "var(--clr-gray-900)"
                    : "var(--clr-leaftech-blue-500)"
                    }`,
                borderRadius: "7px",
                fontFamily: "Nunito Sans",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "1.4rem",
                lineHeight: "1.9rem",
                ...style
            }}
            {...props}
        >
            <div style={{
                display: 'flex',
            }}>
                <span style={{
                    fontSize: "1.2rem",

                }}>{label}</span>

                <Stack gap={1} className="ml-2">

                    {changeByTyping && <>
                        <input className="t-numbers-xxs w-15 rounded border-gray-100 px-1" value={sliderValue[0]} onChange={(e) => setValue([e.target.value, sliderValue[1]])} />
                        <span>{"-"}</span>
                        <input className="t-numbers-xxs w-15 rounded border-gray-100 px-1" value={sliderValue[1]} onChange={(e) => setValue([sliderValue[0], e.target.value])} />
                    </>

                    }
                    {!changeByTyping && <>
                        <span className="t-numbers-xxs" >
                            {sliderValue[0]}
                        </span>
                        <span>{"-"}</span>
                        <span className="t-numbers-xxs" >
                            {sliderValue[1]}
                        </span>
                    </>
                    }
                </Stack>
            </div>
            <div className="w-full">

                <Box
                    sx={{
                        display: "flex",
                        gap: "16px",
                        alignItems: "center",
                        color: "#000",
                        fontSize: "1.2rem",
                    }}
                >
                    <span>{min}</span>
                    <Slider
                        value={sliderValue}
                        onChange={handleChange}
                        min={min}
                        max={max}
                        valueLabelDisplay="off"
                        step={step ?? (min < 1 ? 0.01 : max < 10 ? 0.1 : 1)}
                        size="small"
                        sx={{
                            padding: '2px'
                        }}
                    />
                    <span>{max}</span>
                </Box>

            </div>


        </div>
    );
};


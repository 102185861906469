export default {
  noBuilding: "There is no building to show!",
  title: "your assets",
  filters: {
    "energy class": "energy class",
    location: "location",
    type: "type",
    certification: "certification",
    "construction year": "construction year",
    search: "search",
    buildings: "buildings",
    resetFilters: "Reset filters",
  },
  assetCard: {
    emission: "EMISSIONS",
    pending: "pending",
    epc: "epc",
  },
};

import { Grid } from "@mui/material";
import { Loading } from "../Loading";
import { DataCard, EmptyDataCard } from "../DataCard";
import { useTranslation } from "react-i18next";
import {
  LoadingOrEmptyComponent,
  LoadingOrEmptyWrapper,
} from "../LoadingAndEmptyHandler";

const EmptyState = () => {
  return (
    <div className="h-22 w-1/3 mt-4">
      <EmptyDataCard></EmptyDataCard>
    </div>
  );
};
export const KPIs = ({
  className,
  kpis,
  isLoading,
  xs = 12,
  xl2 = 6,
  title,
}) => {
  const { t } = useTranslation();
  const kpisLabelsAndKeysMapping = [
    {
      label: t("BuildingProfile.kpis.totalEnergyConsumption"),
      dataKey: "total_energy_consumption",
      unit: "kWh/m²/a",
      round: true
    },
    {
      label: t("BuildingProfile.kpis.totalCarbonEmission"),
      dataKey: "total_carbon_emission",
      unit: "kg/m²/a",
      round: true
    },
    {
      label: t("BuildingProfile.kpis.anticipatedStrandingYear"),
      dataKey: "stranding_year",
      unit: "",
      round: false
    },
  ];
  const noData = !(kpis?.length > 0);
  return (
    <article className={`relative min-h-[16rem] ${className}`}>
      {title && <h2 className="t-heading-l mb-4">{title}</h2>}
      <LoadingOrEmptyComponent
        showLoading={isLoading}
        showEmpty={noData}
        emptyComponent={EmptyState}
        height="11rem"
      ></LoadingOrEmptyComponent>
      <Grid container columnSpacing={4} rowSpacing={4}>
        {kpis?.length > 0 &&
          kpisLabelsAndKeysMapping?.map((mapping, index) => {
            if (kpis[0][mapping.dataKey] !== undefined) {
              const kpi = {
                name: mapping.label,
                value: !mapping.round ? kpis[0][mapping.dataKey] : parseFloat(kpis[0][mapping.dataKey])?.toFixed(2),
                unit: mapping.unit,
              };
              return (
                <Grid item key={index} xs={xs} xl2={xl2}>
                  <DataCard kpi={kpi} />
                </Grid>
              );
            }
          })}
      </Grid>
    </article>
  );
};

import { Stack } from "@mui/material";
import React from "react";
import LegendBullet from "./LegendBullet";

export const HorizontalStackedBarChartLegend = ({
  config,
  legends,
  className,
}) => {
  return (
    <Stack spacing={4} className={className}>
      {legends?.map((legend, index) => {
        return (
          <Stack spacing={2} key={index}>
            <LegendBullet
              width="0.8rem"
              height="0.8rem"
              type="circle"
              color={config[legend.label]?.color}
            />
            <span className="t-body-xl">{config[legend.label]?.label}</span>
            <span className="t-body-xl text-gray-500">{`${parseFloat(
              (legend.percentage * 100).toFixed(1)
            )}%`}</span>
          </Stack>
        );
      })}
    </Stack>
  );
};

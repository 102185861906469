import { Grid, Modal, Paper, Stack } from "@mui/material";
import ReactECharts from "echarts-for-react";
import { isEqual } from "lodash";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { CSVLink } from "react-csv";
import { GiHistogram } from "react-icons/gi";
import { IoBarChartOutline, IoPieChartOutline } from "react-icons/io5";
import { LiaChartAreaSolid } from "react-icons/lia";
import { PiChartDonutThin, PiChartLineLight } from "react-icons/pi";
import { TbChartHistogram, TbSum } from "react-icons/tb";
import { TfiLayoutGrid4Alt } from "react-icons/tfi";
import { useDispatch, useSelector } from "react-redux";
import { FaMapLocationDot } from "react-icons/fa6";
import chroma from "chroma-js";
import html2canvas from "html2canvas";
import { saveAs } from "file-saver";

import { useParams } from "react-router-dom";
import {
  Legend,
  ResponsiveContainer,
  Tooltip
} from "recharts";
import { useGetDashboardNew } from "../../api/hooks/allHooks";
import { ButtonNew } from "../../components/ButtonNew";
import { chartTypes } from "../../enums/dynamicDashboard";
import { setDataToDownload } from "../../features/energyPerformance/energyPerformanceSliceNew";
import { distinctFilter, findDataRange, isNumeric, keepKeysFromObjects } from "../../utils/dataManipulation";
import { dateToUCTDatetime, getWeekdayNumber } from "../../utils/date";
import { formatNumberBasedOnUser } from "../../utils/namesManipulation";
import { DynamicFilterFactory } from "../DynamicFormInput";
import { EmptyErrorBoundary, TextErrorBoundary } from "../ErrorBoundary";
import { Icon } from "../Icon";
import { LoadingOrEmptyWrapper } from "../LoadingAndEmptyHandler";
import LegendItem from "./LegendItem";
import { aggregatedSumValue, getAxisNameWithUnit, getFlattenData, getMapPointColor, pivotData } from "../../utils/dynamicDashboard";
import { MyMap } from "../map/Map";
import { ColorBar } from "./Colorbar";

export const diagramInitialConfig = {
  dataPoint: null,
  dataKey: null,
  diagramType: null,
  color: "black",
  partitions: null,
  stacks: null,
  percentageBased: false,
  direction: "horizontal",
  title: null,
};

const DynamicComboChart = ({ xDataKey, ...props }) => {
  const dispatch = useDispatch()
  const {
    title,
    id,
    data: withoutIndexData,
    chartsConfigs,
    sort,
    sortBy,
    legendsPosition,
    isFullScreen,
    getUnit
  } = props

  if (!withoutIndexData?.length || !xDataKey) return;

  const data = withoutIndexData?.map((d, index) => ({
    ...d,
    index,
  }));
  const xIsNumeric = data?.some(row => isNumeric(row[xDataKey]))

  const aggregatedData = xIsNumeric ? data : aggregatedSumValue({
    data,
    aggregateColumns: chartsConfigs?.filter((config) => config.dataKey)?.map((config) => config.dataKey),
    groupByColumns: [xDataKey],
  })

  if (sort) {
    aggregatedData?.sort((a, b) => a[sortBy] - b[sortBy]);
  } else if (xDataKey) {
    aggregatedData?.sort((a, b) => a[xDataKey] - b[xDataKey]);
  }

  // Remove hardcoded value
  const sortedData =
    xDataKey !== "index" || !sort
      ? aggregatedData
      : aggregatedData?.map((d, index) => ({ ...d, index }));

  const xAxisValues = sortedData?.map((row) => row[xDataKey]);


  let xAxis = xIsNumeric ? {
  } : [
    {
      data: xAxisValues,
      axisPointer: {
        type: "shadow",
      },
    }
  ]

  const xUnit = getUnit({ quantity: xDataKey })
  const xAxisName = getAxisNameWithUnit({ dataKey: xDataKey, unit: xUnit })
  xAxis = { ...xAxis, nameGap: 25, nameLocation: 'center', name: xAxisName }


  let yAxis = xIsNumeric ? [{
  }] : [
    {
      type: "value",
    },
  ]

  const selectedConfigs = chartsConfigs?.filter((config) => config.dataKey)

  const yAxisUnits = selectedConfigs.map(config => getAxisNameWithUnit({ unit: getUnit({ quantity: config.dataKey }) }))
  const yAxisUnitUnique = yAxisUnits.filter(distinctFilter)
  const yAxisUnitText = yAxisUnitUnique.join(' - ')
  yAxis = [{ ...yAxis[0], name: yAxisUnitText, nameTextStyle: { align: yAxisUnitUnique?.length > 1 ? 'left' : 'center' } }]


  const option = {
    xAxis,
    yAxis,
    series: selectedConfigs?.map((config) => {
      return {
        name: config.dataKey,
        type: config.diagramType,
        color: config.color,
        tooltip: {
          trigger: 'item',
          valueFormatter: echartsDefaultTooltipFormatter,
        },
        data: xIsNumeric ? sortedData?.map((row) => [row[xDataKey], row[config?.dataKey]]) : sortedData?.map((row) => row[config?.dataKey]),
        emphasis: {
          focus: "series",
        },
      };
    }),
  };

  const dataKeysForDownload = selectedConfigs?.map(config => config.dataKey)
  if (dataKeysForDownload) {
    dataKeysForDownload?.push(xDataKey)
    let dataToDownload = keepKeysFromObjects(xIsNumeric ? data : aggregatedData, dataKeysForDownload)
    dispatch(setDataToDownload({ id: id, data: dataToDownload }))
  }

  return <DynamicEchartsChart option={option} title={title} legendsPosition={legendsPosition} isFullScreen={isFullScreen} />;
};



const predefinedSteps = [
  0.001,
  0.002,
  0.005,
  0.01,
  0.02,
  0.05,
  0.1,
  0.2,
  0.5,
  1,
  2,
  5,
  10,
  25,
  50,
  100,
  250,
  500,
  1000,
  2000,
  5000,
  10000,
  25000,
  50000,
  100000,
  250000,
  500000,
  1000000,
  2500000,
  5000000,
  10000000,
  25000000,
  50000000,
];

const generateAxisValues = (minValue, maxValue, step) => {
  if (minValue === undefined || maxValue === undefined) return;
  let bestStep;
  const range = maxValue - minValue;
  if (step !== undefined) bestStep = step;
  else {
    const ticksNos = [...Array(5).keys()]?.map((e) => e + 5);
    const upperBounds = predefinedSteps?.map((step) => {
      for (const tickNo of ticksNos) {
        if (step * tickNo > range) return step * tickNo;
      }
      return null;
    });
    const bestStepIndex = upperBounds.indexOf(
      Math.min(...upperBounds.filter((e) => e !== null))
    );
    bestStep = predefinedSteps[bestStepIndex];
  }
  if (bestStep === undefined || bestStep === null) return;
  const axisValues = [];
  const roundedMinValue = Math.floor(minValue / bestStep) * bestStep;
  for (let i = 0; i <= Math.ceil(range / bestStep) + 2; i++) {
    axisValues.push(roundedMinValue + i * bestStep);
    if (roundedMinValue + i * bestStep >= maxValue) break;
  }
  return axisValues;
};

const generateHistData = ({ data, dataKeys, axisValues }) => {
  if (!data?.length || !axisValues?.length) return;
  const histData = [];
  for (const i in axisValues) {
    if (i == 0) continue;
    const min = axisValues[i - 1];
    const max = axisValues[i];
    const label = `${min} - ${max}`;
    const allCounts = {};
    for (const dataKey of dataKeys) {
      const count = data?.reduce((total, current) => {
        const value = current[dataKey];
        if ((value > min || (value === min && i === 1)) && value <= max)
          total++;
        return total;
      }, 0);
      allCounts[dataKey] = count;
    }
    histData.push({ name: label, ...allCounts });
  }
  return histData;
};

const DynamicHistogramChart = (props) => {
  const { data, chartsConfigs, title, legendsPosition, isFullScreen, id } = props
  const dispatch = useDispatch()
  const dataKeys = chartsConfigs?.map((c) => c.dataKey);
  if (!dataKeys?.filter((k) => k)?.length) return;
  const minMax = findDataRange({ data, dataKeys });
  if (!minMax) return;
  const { min, max } = minMax;
  const axisValues = generateAxisValues(min, max);

  const histData = generateHistData({ data, dataKeys, axisValues });
  var option = {
    xAxis: {
      data: histData?.map((row) => row.name),
    },
    yAxis: {
      type: "value",
      name: 'Count'
    },
    series: chartsConfigs?.map((config, i) => {
      return {
        name: config.dataKey,
        type: "bar",
        color: config.color,
        stack: i,
        tooltip: {
          valueFormatter: echartsDefaultTooltipFormatter,
        },
        data: histData?.map((row) => row[config?.dataKey]),
        emphasis: {
          focus: "series",
        },
      };
    }),
  };

  dispatch(setDataToDownload({ id, data: histData?.map((row) => ({ ...row, name: `[${row?.name}]` })) }))

  return <DynamicEchartsChart option={option} title={title} legendsPosition={legendsPosition} isFullScreen={isFullScreen} />;
};

const echartsDefaultTooltipFormatter = (value) => formatNumberBasedOnUser(value)

const legendsWidth = 200
const legendsWidthFullScreen = 400

const echartsDefaultOptions = {
  tooltip: {
    position: "top",
    trigger: "axis",
    axisPointer: {
      type: "cross",
      crossStyle: {
        color: "#999",
      },
    },
  },
  toolbox: {
    feature: {
      dataView: { show: true, readOnly: false },
      saveAsImage: { show: true },
    },
  },
  dataZoom: {
    type: "slider",
    height: 16,
    bottom: 30,
  },

};

const legendConfigAtRight = {
  legend: {
    type: "scroll",
    orient: "vertical",
    left: 'right',
    top: 40,
    align: 'right',
    textStyle: {
      width: legendsWidth,
      overflow: 'truncate'
    }
  },
  grid: {
    left: 10,
    right: legendsWidth + 30,
    bottom: 70,
    top: 60,
    containLabel: true,
  },
}

const legendsConfigAtBottom = {
  legend: {
    type: "scroll",
    orient: "horizontal",
    right: 40,
    bottom: 0,
    left: 10,
  },
  grid: {
    left: 10,
    right: 10,
    bottom: 70,
    top: 60,
    containLabel: true,
  },
}

const DynamicEchartsChart = ({ option, ...props }) => {
  const eChartsRef = useRef();

  let mergedOptions = {
    ...echartsDefaultOptions,
    title: {
      text: props.title,
      left: "center",
    },
  };

  if (props?.legendsPosition === 'right') {
    mergedOptions = { ...mergedOptions, ...legendConfigAtRight }
    if (props.isFullScreen) {
      mergedOptions = {
        ...mergedOptions,
        legend: {
          ...mergedOptions.legend, textStyle: {
            ...mergedOptions.legend.textStyle,
            width: legendsWidthFullScreen
          }
        },
        grid: {
          ...mergedOptions.grid,
          right: legendsWidthFullScreen + 30,
        },
      }
    }
  } else {
    mergedOptions = { ...mergedOptions, ...legendsConfigAtBottom }
  }

  if (option) mergedOptions = { ...mergedOptions, ...option };

  if (mergedOptions?.yAxis)
    mergedOptions = {
      ...mergedOptions, yAxis: [{
        nameTextStyle: { align: 'left' }, ...mergedOptions?.yAxis?.[0],
      }]
    }

  if (eChartsRef && eChartsRef.current)
    eChartsRef.current?.getEchartsInstance().setOption(mergedOptions, true);

  return (
    <ReactECharts
      option={mergedOptions}
      style={{ height: "100%", width: "100%" }}
      ref={eChartsRef}
      {...props}
    />
  );
};

const DynamicPieChart = ({ data, dataPoint, chartsConfigs, ...props }) => {
  const {
    width,
    height,
    chartType,
    dataTransformator,
    config,
    legendsPosition,
    title,
    id,
    isFullScreen
  } = props;
  const dispatch = useDispatch()

  if (!chartsConfigs.length) return;
  const diagramConfig = chartsConfigs[0];
  const { partitions, stacks } = diagramConfig;

  let flattenData = dataTransformator(data, { ...config, partitions, stacks })

  let dataStacks;
  if (!stacks) dataStacks = [];
  else {
    dataStacks = flattenData?.map((e) => e[stacks])?.filter(distinctFilter);
  }

  let allAggregatedData = [];

  if (!dataStacks.length) {
    allAggregatedData.push(
      aggregatedSumValue({
        data: flattenData,
        aggregateColumns: [dataPoint],
        groupByColumns: [partitions],
      })
    );
  } else {
    for (const stack of dataStacks) {
      const filteredData = flattenData.filter((e) => e[stacks] === stack);

      allAggregatedData.push(
        aggregatedSumValue({
          data: filteredData,
          aggregateColumns: [dataPoint],
          groupByColumns: [partitions],
        })
      );
    }
  }
  const noOfCharts = allAggregatedData.length;

  let availableWidth = legendsPosition === 'right' ? width - legendsWidth : width
  const xOuterRadius = availableWidth / (3 * noOfCharts + 1);
  const yOuterRadius = height / 3;
  const outerRadius = Math.min(xOuterRadius, yOuterRadius);
  const marginX = availableWidth - (3 * noOfCharts + 1) * outerRadius;

  const isDoughnut = chartType === chartTypes.DOUGHNUT;
  const doughnutRadiusUnit = Math.min(
    availableWidth / (5 * noOfCharts + 0.25),
    height / (5 * noOfCharts + 0.25)
  );
  var option = {
    tooltip: {
      trigger: "item",
    },
    title: [
      {
        text: title,
        left: "center",
      },
      ...dataStacks?.map((stackName, stackIndex) => ({
        subtext: !isDoughnut && stackName,
        top: height / 2 - outerRadius - 30,
        textAlign: "center",
        left: isDoughnut
          ? "50%"
          : `${marginX / 2 + (3 * stackIndex + 2) * outerRadius}`,
      })),
    ],
    series: allAggregatedData?.map((data, stackIndex) => {
      return {
        name: partitions + " " + dataStacks[stackIndex],
        type: "pie",
        center: [
          isDoughnut
            ? `${100 * availableWidth / (2 * width)}%`
            : `${marginX / 2 + (3 * stackIndex + 2) * outerRadius}`,
          "50%",
        ],
        radius: [
          isDoughnut ? (2 * stackIndex + 1) * doughnutRadiusUnit : 0,
          isDoughnut ? (2 * stackIndex + 2) * doughnutRadiusUnit : outerRadius,
        ],
        avoidLabelOverlap: true,
        itemStyle: {
          borderRadius: isDoughnut ? 4 : 6,
          borderColor: "#fff",
          borderWidth: 1,
        },
        label: {
          show: true,
          position: "outer",
          formatter: '{b}({d}%)'
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 14,
            fontWeight: "bold",
            position: "inside",
          },
        },
        labelLine: {
          show: true,
        },
        color: data?.map((_row, index) => {
          // const utilityName = partitions!=='sensor'?null:row[partitions]
          return getColor(stackIndex, index);
        }),
        data: data?.map((row) => ({
          name: row[partitions],
          value: row[dataPoint],
        })),
        tooltip: {
          valueFormatter: echartsDefaultTooltipFormatter,
        },
      };
    }),
  };
  const dataToDownload = allAggregatedData?.reduce((total, current, stackIndex) => {
    const name = dataStacks[stackIndex]
    return [...total, ...(current.map(r => ({ ...r, name })))]
  }, [])

  dispatch(setDataToDownload({ id: id, data: dataToDownload }))

  return <DynamicEchartsChart option={option} legendsPosition={legendsPosition} isFullScreen={isFullScreen} />;
};

const LegendItemWithValue = ({
  label,
  value,
  color,
  hasBullet,
  direction,
  className,
}) => {
  return (
    <Stack
      spacing={4}
      className={`w-full legend justify-between ${className}`}
      flexDirection={direction}
    >
      <LegendItem
        className=""
        bulletType="rectangle"
        bulletWidth={hasBullet ? "0.8rem" : 0}
        bulletHeight={hasBullet ? "0.4rem" : 0}
        color={color}
        label={label}
      />
      <span className=" t-body-n text-gray-500">
        {/* {value >= 1000 ? addCommaToNumber(parseInt(value)) : value?.toFixed(2)}{" "} */}
        {formatNumberBasedOnUser(value)}
      </span>
    </Stack>
  );
};

const DynamicKPI = ({
  data: withoutIndexData,
  dataPoint,
  chartsConfigs,
  config,
  dataTransformator,
  ...props
}) => {
  let { height, chartType, title, xDataKey, id } = props;
  const dispatch = useDispatch()
  const diagramConfig = chartsConfigs[0];
  let { partitions, stacks, percentageBased, direction } = diagramConfig;

  if (chartType === chartTypes.LINE || chartType === chartTypes.AREA) stacks = null
  if (chartType === chartTypes.KPI) xDataKey = null
  const data = withoutIndexData?.map((d, index) => ({
    ...d,
    index,
  }));

  let flattenData = dataTransformator(data, { ...config, partitions, stacks, xDataKey })

  let dataStacks;
  if (!stacks) dataStacks = [];
  else {
    dataStacks = flattenData?.map((e) => e[stacks])?.filter(distinctFilter);
  }

  let dataPartitions;
  if (!partitions) dataPartitions = [];
  else {
    dataPartitions = flattenData
      ?.map((e) => e[partitions])
      ?.filter(distinctFilter);
  }

  let kpiAggregatedData;
  const pivotColumn = partitions && stacks
    ? stacks + " - " + partitions
    : partitions || stacks
  if (partitions && stacks) {
    flattenData = flattenData?.map((e) => ({
      ...e,
      [pivotColumn]: e[stacks] + " - " + e[partitions],
      [stacks]: null,
      [partitions]: null,
    }));
  }
  const isStacked = partitions || stacks;
  if (!isStacked) {
    kpiAggregatedData = aggregatedSumValue({
      data: flattenData,
      aggregateColumns: [dataPoint],
      groupByColumns: [],
    });
  } else {
    var pivotColumnValues = flattenData
      ?.map((e) => e[pivotColumn])
      ?.filter(distinctFilter);

    // Remove hardcoded value
    flattenData = aggregatedSumValue({
      data: flattenData,
      aggregateColumns: [dataPoint],
      groupByColumns: [xDataKey, pivotColumn, "dataPoint"],
    });

    const pivotedData = pivotData(flattenData, pivotColumn, dataPoint);
    kpiAggregatedData = aggregatedSumValue({
      data: pivotedData,
      aggregateColumns: pivotColumnValues,
      groupByColumns: [],
    });
  }

  const isVertical = direction === "vertical";

  dispatch(setDataToDownload({ id: id, data: kpiAggregatedData }))

  return (
    <>
      <h4 className="text-center t-body-n uppercase mt-4">{title}</h4>
      <Stack
        className="w-full"
        flexDirection={isVertical ? "column" : "row"}
        justifyContent={"space-around"}
        height={height - 48}
      >
        {!isStacked ? (
          <LegendItemWithValue
            label={dataPoint}
            value={kpiAggregatedData[0][dataPoint]}
            hasBullet={false}
            direction={"column"}
          />
        ) : partitions && !stacks ? (
          dataPartitions?.map((p, i) => (
            <LegendItemWithValue
              label={p}
              value={kpiAggregatedData[0][p]}
              hasBullet={false}
              direction={isVertical ? "row" : "column"}
              className={"w-full"}
            />
          ))
        ) : (
          <Stack
            flexDirection={isVertical ? "column" : "row"}
            justifyContent={"space-between"}
            className={"h-full w-full"}
          >
            {dataStacks?.map((s, i) => {
              return (
                <div className="w-full text-center t-body-n uppercase">
                  <p className="mt-2">{s}</p>
                  <Stack
                    flexDirection={"column"}
                    justifyContent={"space-between"}
                    className="w-full"
                  >
                    {dataPartitions?.map((p, j) => (
                      <LegendItemWithValue
                        label={p}
                        value={kpiAggregatedData[0][`${s} - ${p}`]}
                        hasBullet={true}
                      />
                    ))}{" "}
                  </Stack>
                </div>
              );
            })}
          </Stack>
        )}

        <Legend />
        <Tooltip />
      </Stack>
    </>
  );

};

const DynamicStackedChart = ({
  data: withoutIndexData,
  dataPoint,
  chartsConfigs,
  config,
  dataTransformator,
  ...props
}) => {
  let { chartType, title, xDataKey, sort, legendsPosition, isFullScreen, id, getUnit } = props;
  const dispatch = useDispatch()
  const diagramConfig = chartsConfigs[0];
  let { partitions, stacks, percentageBased, direction } = diagramConfig;

  if (chartType === chartTypes.LINE || chartType === chartTypes.AREA) stacks = null
  if (chartType === chartTypes.KPI) xDataKey = null
  const data = withoutIndexData?.map((d, index) => ({
    ...d,
    index,
  }));

  let flattenData = dataTransformator(data, { ...config, partitions, stacks, xDataKey })

  let dataStacks;
  if (!stacks) dataStacks = [];
  else {
    dataStacks = flattenData?.map((e) => e[stacks])?.filter(distinctFilter);
  }

  let dataPartitions;
  if (!partitions) dataPartitions = [];
  else {
    dataPartitions = flattenData
      ?.map((e) => e[partitions])
      ?.filter(distinctFilter);
  }

  let allAggregatedData = [];
  const pivotColumn =
    (chartType === chartTypes.BAR)
      ? partitions && stacks
        ? stacks + " - " + partitions
        : partitions || stacks
      : partitions;
  if (partitions && stacks && (chartType === chartTypes.BAR)) {
    flattenData = flattenData?.map((e) => ({
      ...e,
      [pivotColumn]: e[stacks] + " - " + e[partitions],
      [stacks]: null,
      [partitions]: null,
    }));
  }
  const isStacked = partitions || stacks;
  if (!isStacked) {
    allAggregatedData = aggregatedSumValue({
      data: flattenData,
      aggregateColumns: [dataPoint],
      groupByColumns: [xDataKey],
    });
  } else {
    var pivotColumnValues = flattenData
      ?.map((e) => e[pivotColumn])
      ?.filter(distinctFilter);

    // Remove hardcoded value
    flattenData = aggregatedSumValue({
      data: flattenData,
      aggregateColumns: [dataPoint],
      groupByColumns: [xDataKey, pivotColumn, "dataPoint"],
    });

    const pivotedData = pivotData(flattenData, pivotColumn, dataPoint);

    allAggregatedData = aggregatedSumValue({
      data: pivotedData,
      aggregateColumns: pivotColumnValues,
      groupByColumns: [xDataKey],
    });
  }

  if (chartType === chartTypes.LINE) {
    if (!partitions && sort)
      allAggregatedData?.sort((a, b) => b[dataPoint] - a[dataPoint]);
    if (partitions && sort) {
      const sorted = {};
      pivotColumnValues.forEach((v) => {
        const pivotValues = allAggregatedData?.map((d) => d[v]);
        pivotValues.sort((a, b) => b - a);
        sorted[v] = pivotValues;
      });
      const sortedAllAggregatedData = [];
      for (const i in allAggregatedData) {
        const sortedIthIndex = {};
        for (const v of pivotColumnValues) {
          sortedIthIndex[v] = sorted[v][i];
        }
        sortedAllAggregatedData.push({ i, ...sortedIthIndex });
      }
      allAggregatedData = sortedAllAggregatedData;
    }
  }
  const isVertical = direction === "vertical";

  const xAxisValues = xDataKey
    ? allAggregatedData?.map((row, index) =>
      partitions && sort ? index : row[xDataKey]
    )
    : ["Total"];

  let xAxisOption = !isVertical
    ? {
      data: xAxisValues,
      axisPointer: {
        type: "shadow",
      },
    }
    : {
      type: "value",
    };

  const xUnit = getUnit({ quantity: xDataKey })
  const xAxisName = getAxisNameWithUnit({ dataKey: xDataKey, unit: xUnit })

  const dataPointUnit = getUnit({ quantity: dataPoint })
  const yAxisName = getAxisNameWithUnit({ dataKey: dataPoint, unit: dataPointUnit })

  xAxisOption = { ...xAxisOption, nameGap: 25, nameLocation: 'center', name: !isVertical ? xAxisName : yAxisName }

  let yAxisOption = isVertical
    ? {
      data: xAxisValues,
      axisPointer: {
        type: "shadow",
      },
    }
    : {
      type: "value",
      name: dataPoint
    };

  yAxisOption = { ...yAxisOption, name: !isVertical ? yAxisName : xAxisName }

  var option = {
    xAxis: [xAxisOption,],
    yAxis: [yAxisOption,],
    series: !isStacked
      ? [
        {
          name: dataPoint,
          color: getColor(0),
          type: chartType === chartTypes.AREA ? chartTypes.LINE : chartType,
          areaStyle: chartType === chartTypes.AREA ? {} : undefined,
          data: allAggregatedData?.map((row) => row[dataPoint]),
        },
      ]
      : pivotColumnValues?.map((value, i) => {
        const stack = dataStacks?.find((s) => value?.includes(s));
        const stackIndex = dataStacks?.findIndex((s) => value?.includes(s));
        const partitionIndex = dataPartitions?.findIndex((p) =>
          value?.includes(p)
        );

        return {
          name: value,
          type: chartType === chartTypes.AREA ? chartTypes.LINE : chartType,
          areaStyle: chartType === chartTypes.AREA ? {} : undefined,
          color: stack ? getColor(stackIndex, partitionIndex) : getColor(i),
          stack:
            chartType === chartTypes.LINE
              ? null
              : chartType === chartTypes.AREA
                ? "0"
                : stack ?? "0",
          tooltip: {
            valueFormatter: echartsDefaultTooltipFormatter,
          },
          data: allAggregatedData?.map((row) => row[value]),
          emphasis: {
            focus: "series",
          },
        };
      }),
  };
  dispatch(setDataToDownload({ id, data: allAggregatedData }))

  return <DynamicEchartsChart option={option} title={title} legendsPosition={legendsPosition} isFullScreen={isFullScreen} />;
};

const DynamicHeatmap = ({
  data,
  dataPoint,
  xDataKey,
  yDataKey,
  chartsConfigs,
  dataTransformator,
  config,
  id,
  title
}) => {
  const dispatch = useDispatch()
  if (!dataPoint || !xDataKey || !yDataKey) return;

  const diagramConfig = chartsConfigs[0];
  let { partitions, stacks } = diagramConfig;

  let { flattenData, xLabels, yLabels } = dataTransformator(data, { ...config, partitions, stacks, xDataKey, yDataKey })
  const aggregatedData = aggregatedSumValue({
    data: flattenData,
    groupByColumns: [xDataKey, yDataKey],
    aggregateColumns: [dataPoint],
  });

  const xyzData = [];
  yLabels.forEach((y, yIndex) => {
    xLabels.forEach((x, xIndex) => {
      const xyRecordIndex = aggregatedData.findIndex(
        (r) => r[xDataKey] === x && r[yDataKey] === y
      );
      const z =
        xyRecordIndex === -1 ? 0 : aggregatedData[xyRecordIndex][dataPoint];
      xyzData.push([xIndex, yIndex, z || 0]);
    });
  });
  const defaultColor = "black";
  const color = chartsConfigs?.length ? chartsConfigs[0]?.color : defaultColor;

  var option = {
    legend: null,
    tooltip: {
      position: "top",
    },
    grid: {
      left: 10,
      right: 70,
      bottom: 60,
      top: 40,
      containLabel: true,
    },
    xAxis: {
      data: xLabels,
      splitArea: {
        show: true,
      },
      name: xDataKey,
      nameLocation: 'center',
      nameGap: 20
    },
    yAxis: [{
      data: yLabels,
      splitArea: {
        show: true,
      },
      name: yDataKey
    }],
    visualMap: {
      min: Math.min(...(aggregatedData?.map((row) => row[dataPoint]) ?? [])),
      max: Math.max(...(aggregatedData?.map((row) => row[dataPoint]) ?? [])),
      calculable: true,
      orient: "vertical",
      left: "right",
      top: 40,
      inRange: {
        color: ["white", color ?? defaultColor],
      },
    },
    series: [
      {
        name: dataPoint,
        type: "heatmap",
        data: xyzData,
        label: {
          show: false,
        },
        tooltip: {
          valueFormatter: echartsDefaultTooltipFormatter,
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
          },
        },
      },
    ],
  };
  dispatch(setDataToDownload({ id, data: aggregatedData }))

  return <DynamicEchartsChart option={option} title={title} />;
};


const DynamicMiniMap = ({
  data,
  dataPoint,
  eventHandlers,
  xDataKey,
  yDataKey,
  chartsConfigs,
  dataTransformator,
  config,
  id,
  getUnit,
  title
}) => {
  const dispatch = useDispatch()

  const nonNullValues = data?.filter(point => isNumeric(point[dataPoint]))
  const { min, max } = findDataRange({ data: nonNullValues, dataKeys: [dataPoint] })

  const colorScale = chroma.scale([
    "#0f0",
    "#ff0",
    "#f00",
  ]);


  // TODO: Remove this hardcoding Building Name
  const dataWithColorAndValue = data?.map(point => ({
    ...point, value: point[dataPoint], center: [point.lat, point.lon],
    color: getMapPointColor({ value: point[dataPoint], min, max, colorScale }),
    name: point['Building Name']
  }))

  const colorMin = getMapPointColor({ value: min, min, max })
  const colorMax = getMapPointColor({ value: max, min, max })

  const dataToDownload = dataWithColorAndValue?.map(point => {
    return { center: point.center, [dataPoint]: point.value, color: point.color }
  })

  const dataPointUnit = getUnit({ quantity: dataPoint })
  const dataPointUnitText = getAxisNameWithUnit({ unit: dataPointUnit })

  const screenshotRef = useRef()

  dispatch(setDataToDownload({ id, data: dataToDownload }))

  return (
    <div className="h-full w-full">
      <Icon className='absolute right-1 top-[2px]  z-10' color={'var(--clr-gray-500)'} size={'md'} iconName={'Download'} onClick={() => {
        html2canvas(screenshotRef.current, {
          useCORS: true,
        }).then((canvasSnapshot) => {
          const screenshot = canvasSnapshot.toDataURL('image/png');
          saveAs(screenshot, `${dataPoint}.png`);
        });
      }} />
      <Stack ref={screenshotRef} flexDirection={'column'} className="w-full h-full relative">


        <div className="h-8 text-center">
          <span className="t-heading-l mb-1">{dataPoint + dataPointUnitText}</span>
        </div>

        <div className="w-full flex-1 relative">
          <MyMap
            resizeTriggerVariables={[config.w, config.h]}
            rerenderVariables={[data]}
            data={dataWithColorAndValue}
            eventHandlers={eventHandlers(data, config)}
          />
          <ColorBar min={min} max={max} colorMin={colorMin} colorMax={colorMax}
            className=" absolute right-2 z-[999]"
          />

          {/* <div className="w-8 h-3/4 bg-[red] absolute right-2 top-1/2 -translate-y-1/2 z-[999] rounded" /> */}
        </div>
      </Stack>
    </div>

  )
}

// TODO: Remove hardcoded
const ChartType = ({ chartType, xDataKey = "datetime", ...props }) => {

  if (!props.chartsConfigs?.length) return;

  // TODO: Remove hardcoded
  if (xDataKey === "dayOfWeek")
    props?.data?.sort(
      (a, b) => getWeekdayNumber(a?.dayOfWeek) - getWeekdayNumber(b?.dayOfWeek)
    );

  if (chartType === chartTypes.COMPOSED)
    return <DynamicComboChart {...{ xDataKey, ...props }} />;

  if (chartType === chartTypes.HISTOGRAM)
    return <DynamicHistogramChart {...{ ...props }} />;

  if ([chartTypes.PIE, chartTypes.DOUGHNUT].includes(chartType))
    return <DynamicPieChart {...{ ...props, chartType }} />;

  if ([chartTypes.BAR, chartTypes.AREA, chartTypes.LINE].includes(chartType))
    return <DynamicStackedChart {...{ ...props, chartType, xDataKey }} />;

  if (chartType === chartTypes.KPI)
    return <DynamicKPI {...{ ...props, chartType, xDataKey }} />;

  //TODO: dataPoint change doesn't rerender the heatmap? Why error?
  if (chartType === chartTypes.HEATMAP && props.dataPoint) {
    return <DynamicHeatmap {...{ xDataKey, ...props }} />;
  }
  if (chartType === chartTypes.MINI_MAP && props.dataPoint) {
    return <DynamicMiniMap {...{ xDataKey, ...props }} />;
  }
};


const colorGroups = [
  "secondary-blue",
  "mystic-red",
  "vivid-orchid",
  "plunge",
  "star",
  "gray",
  "leaftech-blue",
  "tangerine",
  "cathode-green",
  "bright-indigo",
];

const spectrum = [600, 400, 200, 800];
let flatColors = [];
spectrum.forEach((s) => {
  colorGroups.forEach((group) => {
    flatColors.push(`--clr-${group}-${s}`);
  });
});

const stackedColors = spectrum.map((s) => {
  return colorGroups.map((group) => `--clr-${group}-${s}`);
});

const getColor = (stackIdx, partitionIdx) => {
  var style = getComputedStyle(document.documentElement);
  const isStacked = stackIdx >= 0;
  const isPartitioned = partitionIdx >= 0;

  if (!isStacked && !isPartitioned) {
    return style.getPropertyValue(flatColors[0]).trim();
  }
  if (!(isStacked && isPartitioned)) {
    return style
      .getPropertyValue(
        flatColors[(stackIdx ?? partitionIdx) % flatColors.length]
      )
      .trim();
  }
  const validStackIdx = stackIdx % stackedColors.length;
  const validPartitionIdx = partitionIdx % stackedColors[0].length;

  return style.getPropertyValue(
    stackedColors[validStackIdx][validPartitionIdx]
  );
};



const DynamicChartSettings = ({
  diagrams,
  setDiagrams,
  selectedChartType,
  settings
}) => {

  const handleAddDiagram = () => {
    setDiagrams([...diagrams, diagramInitialConfig]);
  };

  const diagramsSettings = settings?.filter((setting, index) => setting.isInDiagramsPart)
  const otherSettings = settings?.filter((setting, _index) => !setting.isInDiagramsPart)
  const hasMultipleDiagrams = [chartTypes.COMPOSED, chartTypes.HISTOGRAM].includes(selectedChartType)

  return (
    <Stack direction={"column"} className="w-full mt-4 gap-2 ">
      {otherSettings?.map(setting => {
        if (!setting.visible()) return
        const { filterType, ...args } = setting
        const filterFactory = new DynamicFilterFactory(filterType, args)
        const filterInstance = filterFactory.createFilterClassInstance()
        if (filterInstance) {
          const FilterComponent = filterInstance.createComponent()
          return <Stack className="w-full" justifyContent={"space-between"}>
            <span className="block w-1/3 t-heading-m">{setting.filterLabel} :</span>
            {FilterComponent}
          </Stack>
        }
      })}

      {diagrams?.map((diagram, index) => {
        return (
          <>
            {hasMultipleDiagrams && (
              <Stack gap={2} className="items-center mt-1">
                <Icon
                  iconName={"Close"}
                  size={"sm"}
                  color={"red"}
                  onClick={() =>
                    setDiagrams(diagrams.filter((_d, i) => i !== index))
                  }
                />
                <span className="block t-heading-m">Diagram {index + 1}:</span>
              </Stack>
            )}
            {diagramsSettings?.map(setting => {
              if (!setting.visible(index)) return
              setting.config = diagram
              setting.setConfig = setting.setConfigFn(index)

              const { filterType, ...args } = setting
              const filterFactory = new DynamicFilterFactory(filterType, args)
              const filterInstance = filterFactory.createFilterClassInstance()
              if (filterInstance) {
                const FilterComponent = filterInstance.createComponent()
                return <Stack className="w-full" justifyContent={"space-between"}>
                  <span className="block w-1/3 t-heading-m">{setting.filterLabel} :</span>
                  {FilterComponent}
                </Stack>
              }
            })}
          </>
        );
      })}

      {hasMultipleDiagrams && (
        <ButtonNew
          onClick={handleAddDiagram}
          variant="primary"
          size="sm"
          className="mt-1"
        >
          + Add Diagram
        </ButtonNew>
      )}

    </Stack>

  );
};

const ChartFilters = ({ filters }) => {
  return (
    <Stack flexDirection={"column"} gap={2}>
      {filters?.map(filter => {
        const { filterType, ...args } = filter
        const filterFactory = new DynamicFilterFactory(filterType, args)
        const filterInstance = filterFactory.createFilterClassInstance()
        if (filterInstance) {
          const FilterComponent = filterInstance.createComponent()
          return FilterComponent
        }
      })}

    </Stack>
  );
};



const ChartNavbar = ({
  id,
  onClose,
  isVisible,
  setIsVisible,
  handleClick,
  setChartSelectionIsOpen,
  isFullScreen,
  enterFullScreenMode,
  exitFullScreenMode,
  hasFilters
}) => {
  const configsIconsColor = "var(--clr-secondary-blue-500)";
  const items = [
    {
      onClick: onClose,
      iconName: "Trash",
      color: 'var(--clr-mystic-red-500)'
    },
    {
      iconName: isFullScreen ? "FullscreenExit" : "Fullscreen",
      onClick: isFullScreen ? exitFullScreenMode : enterFullScreenMode,
      stroke: true
    },
    {
      iconName: isVisible ? "EyeClose" : "EyeOpen",
      onClick: () => setIsVisible(!isVisible),
    },
    {
      onClick: () => handleClick("settings"),
      iconName: "Settings",
    },
    {
      onClick: () => handleClick("filters"),
      iconName: "Filter",
      inactive: !hasFilters
    },
    {
      iconName: "Piechart",
      onClick: (e) => {
        setChartSelectionIsOpen(
          (chartSelectionIsOpen) => !chartSelectionIsOpen
        );
      },
    },
  ];

  const dataToDownload = useSelector(store => store.energyPerformanceNew).dataToDownload?.[id]
  return (
    <Stack
      className="px-2 h-6 gap-4 w-full bg-blue-50  rounded items-center cursor-grab "
      flexDirection={"row-reverse"}
    >
      {items?.map((item) => {
        if (!item.inactive)
          return (
            <Icon
              className="cancelDrag  cursor-pointer "
              onClick={item.onClick}
              iconName={item.iconName}
              svgClassName="w-4 h-4 hover:scale-[1.3] transition"
              color={item.color || configsIconsColor}
              stroke={item.stroke && configsIconsColor}
            />
          )
      })}
      <EmptyErrorBoundary>
        {dataToDownload &&
          <CSVLink data={dataToDownload} >
            <Icon
              className="cancelDrag  cursor-pointer"
              iconName={"Download"}
              svgClassName="hover:scale-[2] transition"
              size='md'
              color={configsIconsColor}
            />
          </CSVLink>
        }
      </EmptyErrorBoundary>


    </Stack>
  );
};

const DynamicChartNavbarAndSidebar = forwardRef((props, ref) => {
  const {
    onClose,
    setIsVisible,
    isVisible,
    setChartSelectionIsOpen,
    diagrams,
    chartType,
    xCoord,
    isFullScreen,
    setFullScreenChartId,
    id,
    filters,
    settings,
    setDiagrams
  } = props;

  const [openSidebar, setOpenSidebar] = useState(false);
  const [section, setSection] = useState(null);
  const handleClick = (selectedSection) => {
    setSection(selectedSection);
    setOpenSidebar(true);
  };

  useImperativeHandle(ref, () => ({
    childHandleClick: handleClick,
  }));

  const hasFilters = filters?.length > 0

  return (
    <>
      <ChartNavbar
        id={id}
        onClose={onClose}
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        handleClick={handleClick}
        setChartSelectionIsOpen={setChartSelectionIsOpen}
        isFullScreen={isFullScreen}
        enterFullScreenMode={() => setFullScreenChartId(id)}
        exitFullScreenMode={() => setFullScreenChartId(null)}
        hasFilters={hasFilters}
      />
      {openSidebar &&
        <Modal open={openSidebar} onClose={() => setOpenSidebar(false)}>
          <Paper
            className={`cancelDrag fixed z-[6] rounded top-8 bottom-8 w-[60rem] overflow-y-auto ${xCoord < 6 ? "right-8" : "left-8"
              }`}
            style={{
              backdropFilter: "blur(10px)",
              background: "rgba(255,255,255,0.6)",
            }}
          >
            {section === "settings" && (<DynamicChartSettings diagrams={diagrams} setDiagrams={setDiagrams} selectedChartType={chartType} settings={settings} />)}
            {section === "filters" && (<ChartFilters filters={filters} />)}
          </Paper>
        </Modal>
      }
    </>
  );
});

const DynamicChartBody = ({
  isLoading,
  isError,
  generalData,
  specificData,
  width,
  chartType,
  dataPoint,
  title,
  xAxis,
  yAxis,
  diagrams,
  sortValue,
  height,
  legendsPosition,
  id,
  filters,
  settings,
  dataTransformator,
  eventHandlers,
  config,
  getUnit
}) => {
  const data = specificData?.length ? specificData : generalData;

  const [filteredData, setFilteredData] = useState(data);

  useEffect(() => {
    if (!isLoading) {
      const unsortedData = [...data];
      // unsortedData.sort((a, b) => new Date(a.datetime) - new Date(b.datetime));

      let partiallyFilteredData = unsortedData
      filters.forEach(filter => {
        const { filterType, mainDataKey, ...args } = filter
        if (!mainDataKey) return
        const filterFactory = new DynamicFilterFactory(filterType, args)
        const filterInstance = filterFactory.createFilterClassInstance()
        partiallyFilteredData = filterInstance.filterData(partiallyFilteredData, mainDataKey)
      })

      settings.forEach(settings => {
        const { filterType, mainDataKey, useAsDataFilter, ...args } = settings
        if (!mainDataKey || !useAsDataFilter) return
        const filterFactory = new DynamicFilterFactory(filterType, args)
        const filterInstance = filterFactory.createFilterClassInstance()
        partiallyFilteredData = filterInstance.filterData(partiallyFilteredData, mainDataKey)
      })

      setFilteredData(partiallyFilteredData)
    }
  }, [generalData, isLoading, specificData, config]);

  return (
    <div className={`relative h-full`}>
      <LoadingOrEmptyWrapper
        showLoading={isLoading}
        showEmpty={!data?.length || isError}
        height="100%"
      >
        <ResponsiveContainer width={"100%"} height={"100%"}>
          <ChartType
            config={config}
            chartType={chartType}
            dataPoint={dataPoint}
            title={title}
            xDataKey={xAxis}
            yDataKey={yAxis}
            id={id}
            data={filteredData}
            chartsConfigs={diagrams}
            sort={sortValue}
            sortBy={xAxis === "index" ? sortValue : xAxis}
            width={width}
            height={height}
            legendsPosition={legendsPosition}
            dataTransformator={dataTransformator}
            eventHandlers={eventHandlers}
            getUnit={getUnit}
          ></ChartType>
        </ResponsiveContainer>
      </LoadingOrEmptyWrapper>
    </div>
  );
};

const ChartTypeSelection = ({ chartOptions, handleChartSelection }) => {
  return (
    <Grid
      container
      spacing={2}
      className="w-full h-full mt-1"
      justifyContent={"space-evenly"}
    >
      {chartOptions?.map((option, index) => {
        const IconComponent = option.icon;
        return (
          <Grid item xs={3} className="hover:scale-[1.2] transition" key={index}>
            <Stack className="justify-center items-center">
              <Stack
                direction={"column"}
                className="hover:bg-blue-100 w-15 h-15 p-2 bg-[#f5f5ff] rounded-xl cursor-pointer "
                onClick={() => {
                  handleChartSelection(option.type);
                }}
              >
                <IconComponent size={32} color={option.color} />
                <span className="t-label-m">{option.label} </span>
              </Stack>
            </Stack>
          </Grid>
        );
      })}
    </Grid>
  );
};


// The userLanguage parameter is used only to make a rerender when changed.
// TODO: Use a better approach
const DynamicChartMemoized = ({
  id,
  generalData,
  onClose,
  height,
  xCoord,
  config,
  setConfig,
  setFullScreenChartId,
  isFullScreen,
  userLanguage,
  filters,
  settings,
  specificDataGetter,
  dataTransformator,
  eventHandlers,
  getUnit
}) => {
  console.log(id)

  const {
    diagrams,
    sortValue,
    chartType,
    title,
    xAxis,
    yAxis,
    dataPoint,
    legendsPosition,
  } = config;

  const setChartType = (newValue) => setConfig("chartType", newValue);

  const { specificData, isLoading, isError } = specificDataGetter(config)
  const [chartSelectionIsOpen, setChartSelectionIsOpen] = useState(!chartType);

  const navbarAndSidebarRef = useRef();
  const handleChartSelection = (type) => {
    setChartType(type);
    setChartSelectionIsOpen(false);
    navbarAndSidebarRef.current?.childHandleClick("settings");
  };

  const color = "var(--clr-secondary-blue-400)";
  const chartOptions = [
    {
      label: "Area",
      icon: LiaChartAreaSolid,
      type: chartTypes.AREA,
      color,
    },
    {
      label: "Doughnut",
      icon: PiChartDonutThin,
      type: chartTypes.DOUGHNUT,
      color,
    },
    {
      label: "Pie",
      icon: IoPieChartOutline,
      type: chartTypes.PIE,
      color,
    },
    {
      label: "Histogram",
      icon: GiHistogram,
      type: chartTypes.HISTOGRAM,
      color,
    },
    {
      label: "Combo",
      icon: TbChartHistogram,
      type: chartTypes.COMPOSED,
      color,
    },
    {
      label: "Bar",
      icon: IoBarChartOutline,
      type: chartTypes.BAR,
      color,
    },
    {
      label: "Line",
      icon: PiChartLineLight,
      type: chartTypes.LINE,
      color,
    },
    {
      label: "KPI",
      icon: TbSum,
      type: chartTypes.KPI,
      color,
    },
    {
      label: "Heatmap",
      icon: TfiLayoutGrid4Alt,
      type: chartTypes.HEATMAP,
      color,
    },
    {
      label: "Map",
      icon: FaMapLocationDot,
      type: chartTypes.MINI_MAP,
      color,
    },
  ];

  const [isVisible, setIsVisible] = useState(true);

  const setDiagrams = (newValue) => setConfig("diagrams", newValue);

  return (
    <>
      <Paper className={`p-4 h-full`}>
        <div className={`h-full w-full ${chartSelectionIsOpen && "bg-white"}`}>
          <DynamicChartNavbarAndSidebar
            ref={navbarAndSidebarRef}
            setIsVisible={setIsVisible}
            isVisible={isVisible}
            setChartSelectionIsOpen={setChartSelectionIsOpen}
            onClose={onClose}
            diagrams={diagrams}
            chartType={chartType}
            xCoord={xCoord}
            isFullScreen={isFullScreen}
            setFullScreenChartId={setFullScreenChartId}
            id={id}
            filters={filters}
            settings={settings}
            setDiagrams={setDiagrams}
          />
          <TextErrorBoundary>
            {isVisible && (
              <div
                style={{ height: "calc(100% - 24px)" }}
                className=" cancelDrag"
              >
                {chartSelectionIsOpen && (
                  <ChartTypeSelection
                    chartOptions={chartOptions}
                    handleChartSelection={handleChartSelection}
                  />
                )}

                {!chartSelectionIsOpen && (
                  <DynamicChartBody
                    isLoading={isLoading}
                    isError={isError}
                    generalData={generalData}
                    specificData={specificData}
                    chartType={chartType}
                    dataPoint={dataPoint}
                    title={title}
                    xAxis={xAxis}
                    yAxis={yAxis}
                    diagrams={diagrams}
                    sortValue={sortValue}
                    width={"100%"}
                    height={height}
                    legendsPosition={legendsPosition}
                    id={id}
                    filters={filters}
                    settings={settings}
                    dataTransformator={dataTransformator}
                    eventHandlers={eventHandlers}
                    config={config}
                    getUnit={getUnit}
                  />
                )}
              </div>
            )}
          </TextErrorBoundary>
        </div>
      </Paper>
    </>
  );
};

export default React.memo(DynamicChartMemoized, isEqual);

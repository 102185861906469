import { toast } from "react-toastify";
import { clearStoreAndLocalStorage, customApiCall } from "../../utils/axios";
import { getUserFromLocalStorage } from "../../utils/localStorage";
import apiUrls from "../urls";

export const postTranslation = async ({ method, data }) => {
  const url = apiUrls.translation.get("de");
  const resp = await customApiCall[method]({ url, data });
  return resp;
};

export const postSelectedColumns = async ({ data }) => {
  const url = apiUrls.technicalData.selectedColumns.post;
  const resp = await customApiCall.post({ url, data });
  return resp;
};

export const postParentFiles = async ({ activeTab, rowId, data }) => {
  const url = apiUrls.layerFiles.techData[activeTab].detail(rowId);
  const resp = await customApiCall.patch({ url, data });
  return resp;
};

export const deleteParentFilesRow = async ({ activeTab, rowId }) => {
  const url = apiUrls.layerFiles.techData[activeTab].detail(rowId);
  const resp = await customApiCall.delete({ url });
  return resp;
};

export const postChildFiles = async ({ activeTab, layerId, data }) => {
  const url = apiUrls.layerFiles.techData[activeTab].layerDetail(layerId);
  const resp = await customApiCall.patch({ url, data });
  return resp;
};

export const deleteChildFilesRow = async ({ activeTab, layerId }) => {
  const url = apiUrls.layerFiles.techData[activeTab].layerDetail(layerId);
  const resp = await customApiCall.delete({ url });
  return resp;
};
export const getParentFiles = async ({ activeTab, projectId }) => {
  const url = apiUrls.layerFiles.techData[activeTab].list(projectId);
  const resp = await customApiCall.get({ url });
  return resp;
};

export const getChildFiles = async ({ activeTab, layerId }) => {
  const url = apiUrls.layerFiles.techData[activeTab].layerList(layerId);
  const resp = await customApiCall.get({ url });
  return resp;
};

// Energy Performance
export const getDashboardNew = async (inputs) => {
  const url = apiUrls.energyPerformanceNew.data.listFlatten(inputs);
  const resp = await customApiCall.get({ url });
  return resp;
};

export const getProjectInfo = async (projectId) => {
  const url = apiUrls.energyPerformanceNew.projectInfo.list(projectId);
  const resp = await customApiCall.get({ url });
  return resp;
};

export const updateUserLanguage = (userId, language) => {
  const url = apiUrls.user.detail(userId);
  const data = { languages: language };
  customApiCall.patch({ url, data });
};
export const loginUser = (user) => {
  const basicAuthKey = `${process.env.REACT_APP_BASIC_AUTH_USERNAME}:${process.env.REACT_APP_BASIC_AUTH_PASSWORD}`;
  console.log(basicAuthKey)
  const headers = {
    Authorization: "Basic " + window.btoa(basicAuthKey),
  };
  var FormData = require("form-data");
  var data = new FormData();
  data.append("username", user.username);
  data.append("password", user.password);
  data.append("grant_type", "password");

  return customApiCall.post({
    url: apiUrls.user.login.url,
    data: data,
    headers,
    useToken: false,
  });
};

export const logoutUser = async (message) => {
  try {
    var data = new FormData();
    const user = getUserFromLocalStorage();
    data.append("token", user?.access_token);
    data.append("client_id", process.env.REACT_APP_BASIC_AUTH_USERNAME);
    data.append("client_secret", process.env.REACT_APP_BASIC_AUTH_PASSWORD);
    await customApiCall.post({
      url: apiUrls.user.logout.url,
      data: data,
      useToken: false,
    });
    if (message) toast.success(message);
  } catch (error) {
  } finally {
    clearStoreAndLocalStorage();
  }
};

import { useTranslation } from "react-i18next";
import { Loading } from "./Loading";

const Container = ({ children, height }) => (
  <div className="flex justify-center items-center relative" style={{ height }}>
    {children}
  </div>
);

const DefaultEmptyComponent = ({ height }) => {
  const { t } = useTranslation();
  return (
    <Container height={height}>
      <p className=" t-body-xl -mt-2 ">{t("Modernization.goals.noGoals")}</p>
    </Container>
  );
};

export const LoadingOrEmptyComponent = ({
  showLoading,
  showEmpty,
  emptyComponent: EmptyComponent,
  height = "10rem",
}) => {
  if (!EmptyComponent)
    EmptyComponent = () => <DefaultEmptyComponent height={height} />;
  if (showLoading)
    return (
      <Container height={height}>
        <Loading />
      </Container>
    );
  else if (showEmpty) return <EmptyComponent />;
};

export const LoadingOrEmptyWrapper = ({ children, ...props }) => {
  const { showEmpty, showLoading } = props;
  if (showEmpty || showLoading) return <LoadingOrEmptyComponent {...props} />;
  else return children;
};

import React, { useRef, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Icon } from './Icon';


const MyTabScrollButton = (props) => {
    const { direction, ...other } = props;

    return (
        <div className='cursor-pointer' {...other} >
            <Icon className={`cursor-pointer w-10 ${direction === "left" && 'rotate-180'}`}
                iconName={'ChevronRight'} color={'black'} />
        </div>
    );
}

export const ScrollableTabsButtonAuto = ({ tabsLabelsComponents, tabs, addTab, currentTabIndex, setCurrentTabIndex, ...props }) => {
    // const [index, setIndex] = useState(currentTabIndex);

    const handleChange = (event, newValue) => {
        // setIndex(newValue)
        setCurrentTabIndex(newValue);
    };

    const handleAdd = (event, newValue) => {
        // setIndex(tabs?.length)
        // setCurrentTabIndex(tabs?.length);
        addTab()
        tabsRef.current.scrollLeft = tabsRef.current.scrollWidth;
        // setValue(tabs[newValue]);
    };

    const tabsRef = useRef()

    return (
        <Box sx={{}} {...props}>
            <Tabs
                value={currentTabIndex}
                onChange={handleChange}
                variant="scrollable"
                centered={false}
                scrollButtons={true}
                aria-label="scrollable auto tabs example"
                ScrollButtonComponent={(props) => <MyTabScrollButton {...props} />}
            >
                {tabsLabelsComponents?.map((tab, i) => { return (<Tab key={i} label={tab.label} />) })}
                <Tab className='t-heading-l ' label={'+'} onClick={handleAdd}
                    ref={tabsRef}
                />
            </Tabs>
        </Box>
    );
}
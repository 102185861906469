const url = process.env.REACT_APP_API_URL + "/api/projects";
export default {
  folders: {
    download: (projectId, isTechnical) =>
      `${url}/get_folders/?project_id=${projectId}${
        isTechnical ? "&folder_type=envelop" : ""
      }`,
  },
  certificates: {
    download: (projectId, certificateType) =>
      `${url}/get_zip_file_of_all_epc_certificate/?project_id=${projectId}&certificate_epc=${certificateType}`,
  },
};

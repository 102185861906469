import { settingsOptions } from "../../utils/dynamicDashboard";
import { chartTypes } from "../dynamicDashboard";

export const FiltersNames = Object.freeze({
    ID: 'ID',
    NAME: 'Name',
    ADDRESS: 'Addresse',
    PORTFOLIO: 'Portfolio',
    MAIN_USAGE: 'Main usage',
    PREMISE_AREA: 'Premise area',
    FOOTPRINT_AREA: 'Footprint area',
    GROSS_AREA: 'Gross area',
    FLOOR_AMOUMT: 'Floor amount',
    CONSTRUCTION_YEAR: 'Construction year',
    ENERGY_EFFICIENCY_CLASS: 'Energy Efficiency class',
    STRANDING_YEAR: 'Stranding year',
    ENERGY_CONSUMPTION: 'Energy consumption',
    CO2_EMISSION: 'CO2 Emissions',
    HEAT_ENERGY_TYPE: 'Heat energy type',
    HEAT_ENERGY_CONSUMPTION: 'Heat energy consumption',
    ELECTRICITY_CONSUMPTION: 'Electrictiy consumption',
    CASH_IN: 'cash_in',
    LATITUDE: 'lat',
    LONGITUDE: 'lon',
    GROSS_ASSET_VALUE: 'gross_asset_value',
    COUNT: 'Count',

    DIAGRAMS: 'diagrams',
    X_AXIS: 'xAxis',
    Y_AXIS: 'yAxis',
    DATAPOINT: 'dataPoint',
    TITLE: 'title',
    SORT_VALUE: 'sortValue',
    LEGEND_POSITION: 'legendsPosition',

    DIAGRAMS_COLOR: 'color',
    DIAGRAMS_DIRECTION: 'direction',
    DIAGRAMS_STACKS: 'stacks',
    DIAGRAMS_PARTITIONS: 'partitions',
    DIAGRAMS_DIAGRAM_TYPE: 'diagramType',
    DIAGRAMS_DIAGRAM_DATA_KEY: 'dataKey',

})


export const columnsNamesEnums = Object.freeze({
    // 'Accounting Area': '',
    NONE: '',
    INDEX: 'index',
    ID: 'ID',
    PORTFOLIO: 'Portfolio',
    BUILDING_ID: 'Building ID',
    BUILDING_NAME: 'Building Name',
    ZIP: 'Zip',
    CITY: 'City',
    MAIN_DISTRICT: 'Main District',
    SUB_DISTRICT: 'Sub District',
    FULL_ADDRESS: 'Full Address',
    STREET: 'Street',
    BOUGHT_ON: 'Bought on',
    SOLD_ON: 'Sold on',
    RENTAL_UNITS: 'Rental Units',
    YEAR_OF_CONSTRUCTION: 'Year of construction',
    FLOOR_AMOUMT: 'Floor amount',
    HERITAGE_PROTECTED: 'Heritage Protected',
    BUILDING_TYPE_ID: 'Building Type ID',
    BUILDING_TYPE: 'Building Type',
    PRE_FABRICATED: 'Pre Fabricated',
    LOGGIA: 'Loggia',
    BALCONY: 'Balkone',
    FOOTPRINT: 'Footprint',
    PREMISE: 'Premise',
    CEILING_AREA: 'Ceiling Area',
    FACADE_AREA: 'Facade Area',
    GROSS_AREA_WITH_BASEMENT: 'Gross area (incl. Basement)',
    NET_AREA_WITH_BASEMENT: 'Net Area (incl. Basement)',
    GROSS_LETTABLE_AREA_WIGHOUT_BASEMENT: 'Gross Lettable Area (without Basement)',
    NET_LETTABEL_AREA_WITHOUT_BASEMENT: 'Net Lettable Area (without Basement)',
    USE_TYPE: 'Use type',
    ENERGY_EFFICIENCY_CLASS: 'EnA-Eff.kl',
    EPC_CONSUMPTION_KWH_M2A: 'EPC-Consumption kWh/m²/a',
    HEAT_ENERGY_CONSUMPTION_EPC_KWH_M2_A: 'Heat Energy Consumption EPC kWh/m²/a',
    // / 'Electricity Consumption EPC kWh/²/// 'Electricity Consumption EPC kWh/m,
    HEAT_ENERGY_CONSUMPTION_EPC_KWH_A: 'Heat Energy Consumption kWh/a',
    ELECTRICITY_DEMAND_KWH_M2A: 'Electricity Demand kWh/m²/a',
    ELECTRICITY_DEMAND_KWH_A: 'Electricity Demand kWh/a',
    HEAT_ENERGY_TYPE: 'Heat Energy Type',
    DISTRICT_HEATING_AVAILABLE: 'District Heating available',
    NATURAL_GAS_AVAILABLE: 'Natural Gas available',
    TOTAL_ENERGY_CONSUMPTION: 'Total Energy consumption',
    CO2_EMISSION: 'CO2 Emissions',
    STRANDING_YEAR: 'Stranding year',
    LATITUDE: 'lat',
    LONGITUDE: 'lon',
    COUNT: 'Count'
})

export const dataMapping = {
    // 'Accounting Area': ''
    'id': columnsNamesEnums.ID,
    'portfolio': columnsNamesEnums.PORTFOLIO,
    'asset_id': columnsNamesEnums.BUILDING_ID,
    'descriptive_name': columnsNamesEnums.BUILDING_NAME,
    'post_code': columnsNamesEnums.ZIP,
    'city': columnsNamesEnums.CITY,
    'main_district': columnsNamesEnums.MAIN_DISTRICT,
    'sub_district': columnsNamesEnums.SUB_DISTRICT,
    'address': columnsNamesEnums.FULL_ADDRESS,
    'street': columnsNamesEnums.STREET,
    'bought_on': columnsNamesEnums.BOUGHT_ON,
    'sold_on': columnsNamesEnums.SOLD_ON,
    'rental_units': columnsNamesEnums.RENTAL_UNITS,
    'construction_year': columnsNamesEnums.YEAR_OF_CONSTRUCTION,
    'floor_amount': columnsNamesEnums.FLOOR_AMOUMT,
    'heritage_protected': columnsNamesEnums.HERITAGE_PROTECTED,
    'building_type_id': columnsNamesEnums.BUILDING_TYPE_ID,
    'building_type': columnsNamesEnums.BUILDING_TYPE,
    'pre_fabricated': columnsNamesEnums.PRE_FABRICATED,
    'loggia': columnsNamesEnums.LOGGIA,
    'balcony': columnsNamesEnums.BALCONY,
    'footprint_area': columnsNamesEnums.FOOTPRINT,
    'premise_area': columnsNamesEnums.PREMISE,
    'ceiling_area': columnsNamesEnums.CEILING_AREA,
    'facade_area': columnsNamesEnums.FACADE_AREA,
    'gross_asset_basement_included': columnsNamesEnums.GROSS_AREA_WITH_BASEMENT,
    'net_area_basement_included': columnsNamesEnums.NET_AREA_WITH_BASEMENT,
    'gross_lettable_area_without_basement': columnsNamesEnums.GROSS_LETTABLE_AREA_WIGHOUT_BASEMENT,
    'net_lettable_area_without_basement': columnsNamesEnums.NET_LETTABEL_AREA_WITHOUT_BASEMENT,
    'type': columnsNamesEnums.USE_TYPE,
    'energy_label': columnsNamesEnums.ENERGY_EFFICIENCY_CLASS,
    'epc_consumption_kwh_per_square_meter_annum': columnsNamesEnums.EPC_CONSUMPTION_KWH_M2A,
    'heat_energy_consumption_epc_kwh_per_square_meter_annum': columnsNamesEnums.HEAT_ENERGY_CONSUMPTION_EPC_KWH_M2_A,
    // a': '':// 'Electricity Consumption EPC kWh/m,
    'heat_energy_consumption_kwh_per_annum': columnsNamesEnums.HEAT_ENERGY_CONSUMPTION_EPC_KWH_A,
    'electricity_demand_kwh_per_square_meter_annum': columnsNamesEnums.ELECTRICITY_DEMAND_KWH_M2A,
    'electricity_demand_kwh_per_annum': columnsNamesEnums.ELECTRICITY_DEMAND_KWH_M2A,
    'heat_energy_type': columnsNamesEnums.HEAT_ENERGY_TYPE,
    'district_heating_available': columnsNamesEnums.DISTRICT_HEATING_AVAILABLE,
    'natural_gas_available': columnsNamesEnums.NATURAL_GAS_AVAILABLE,
    'energy_consumption': columnsNamesEnums.TOTAL_ENERGY_CONSUMPTION,
    'carbon_dioxide_emission_value': columnsNamesEnums.CO2_EMISSION,
    'stranding_year': columnsNamesEnums.STRANDING_YEAR,
    'latitude': columnsNamesEnums.LATITUDE,
    'longitude': columnsNamesEnums.LONGITUDE,
}


export const columnsUnits = Object.freeze({
    // 'Accounting Area': '',
    [columnsNamesEnums.PORTFOLIO]: '',
    [columnsNamesEnums.BUILDING_ID]: '',
    [columnsNamesEnums.BUILDING_NAME]: '',
    [columnsNamesEnums.ZIP]: '',
    [columnsNamesEnums.CITY]: '',
    [columnsNamesEnums.MAIN_DISTRICT]: '',
    [columnsNamesEnums.SUB_DISTRICT]: '',
    [columnsNamesEnums.FULL_ADDRESS]: '',
    [columnsNamesEnums.STREET]: '',
    [columnsNamesEnums.BOUGHT_ON]: 'Year',
    [columnsNamesEnums.SOLD_ON]: 'Year',
    [columnsNamesEnums.RENTAL_UNITS]: '',
    [columnsNamesEnums.YEAR_OF_CONSTRUCTION]: 'Year',
    [columnsNamesEnums.FLOOR_AMOUMT]: '',
    [columnsNamesEnums.HERITAGE_PROTECTED]: '',
    [columnsNamesEnums.BUILDING_TYPE_ID]: '',
    [columnsNamesEnums.BUILDING_TYPE]: '',
    [columnsNamesEnums.PRE_FABRICATED]: '',
    [columnsNamesEnums.LOGGIA]: '',
    [columnsNamesEnums.BALCONY]: '',
    [columnsNamesEnums.FOOTPRINT]: 'm²',
    [columnsNamesEnums.PREMISE]: 'm²',
    [columnsNamesEnums.CEILING_AREA]: 'm²',
    [columnsNamesEnums.FACADE_AREA]: 'm²',
    [columnsNamesEnums.GROSS_AREA_WITH_BASEMENT]: 'm²',
    [columnsNamesEnums.NET_AREA_WITH_BASEMENT]: 'm²',
    [columnsNamesEnums.GROSS_LETTABLE_AREA_WIGHOUT_BASEMENT]: 'm²',
    [columnsNamesEnums.NET_LETTABEL_AREA_WITHOUT_BASEMENT]: 'm²',
    [columnsNamesEnums.USE_TYPE]: '',
    [columnsNamesEnums.ENERGY_EFFICIENCY_CLASS]: '',
    [columnsNamesEnums.EPC_CONSUMPTION_KWH_M2A]: 'kWh/m²/a',
    [columnsNamesEnums.HEAT_ENERGY_CONSUMPTION_EPC_KWH_M2_A]: 'kWh/m²/a',
    // [columnsNamesEnums.//] / 'Electricity Consumption EPC kWh/²/// 'Electricity Consumption EPC kWh/m,
    [columnsNamesEnums.HEAT_ENERGY_CONSUMPTION_EPC_KWH_A]: 'kWh/a',
    [columnsNamesEnums.ELECTRICITY_DEMAND_KWH_M2A]: 'kWh/m²/a',
    [columnsNamesEnums.ELECTRICITY_DEMAND_KWH_A]: 'kWh/a',
    [columnsNamesEnums.HEAT_ENERGY_TYPE]: '',
    [columnsNamesEnums.DISTRICT_HEATING_AVAILABLE]: '',
    [columnsNamesEnums.NATURAL_GAS_AVAILABLE]: '',
    [columnsNamesEnums.TOTAL_ENERGY_CONSUMPTION]: 'kWh/m²/a',
    [columnsNamesEnums.CO2_EMISSION]: 'kg/m²/a',
    [columnsNamesEnums.STRANDING_YEAR]: 'Year',
    [columnsNamesEnums.LATITUDE]: 'Deg',
    [columnsNamesEnums.LONGITUDE]: 'Deg',
    [columnsNamesEnums.COUNT]: '#'
})

const excluded = ['portfolio', 'last_modernized']

// Accounting Area = Total area or gross_asset_size


const relevantConfigOptions = {
    [chartTypes.AREA]: [
        settingsOptions.X,
        settingsOptions.PARTITIONS,
        settingsOptions.HEIGHT_100,
        settingsOptions.DIRECTION,
        settingsOptions.LEGENDS_POSITION,
        settingsOptions.DATA_POINT,
        settingsOptions.TITLE
    ],
    [chartTypes.BAR]: [
        settingsOptions.X,
        settingsOptions.PARTITIONS,
        settingsOptions.STACKS,
        settingsOptions.DIRECTION,
        settingsOptions.LEGENDS_POSITION,
        settingsOptions.DATA_POINT,
        settingsOptions.TITLE,
    ],
    [chartTypes.PIE]: [settingsOptions.PARTITIONS, settingsOptions.STACKS,
    settingsOptions.TITLE,
    settingsOptions.LEGENDS_POSITION,
    settingsOptions.DATA_POINT

    ],
    [chartTypes.DOUGHNUT]: [settingsOptions.PARTITIONS, settingsOptions.STACKS,
    settingsOptions.TITLE,
    settingsOptions.LEGENDS_POSITION,
    settingsOptions.DATA_POINT,
    ],
    [chartTypes.LINE]: [
        settingsOptions.TITLE,
        settingsOptions.X,
        settingsOptions.PARTITIONS,
        settingsOptions.SORT_BY,
        settingsOptions.DIRECTION,
        settingsOptions.LEGENDS_POSITION,
        settingsOptions.DATA_POINT
    ],
    [chartTypes.COMPOSED]: [
        settingsOptions.TITLE,
        settingsOptions.X,
        settingsOptions.QUANTITY,
        settingsOptions.DIAGRAM_TYPE,
        settingsOptions.COLOR,
        settingsOptions.LEGENDS_POSITION,
    ],
    [chartTypes.KPI]: [
        settingsOptions.TITLE,
        settingsOptions.PARTITIONS, settingsOptions.STACKS, settingsOptions.DIRECTION,
        settingsOptions.DATA_POINT

    ],
    [chartTypes.HISTOGRAM]: [
        settingsOptions.TITLE,
        settingsOptions.QUANTITY, settingsOptions.COLOR, settingsOptions.LEGENDS_POSITION],
    [chartTypes.HEATMAP]: [
        settingsOptions.TITLE,
        settingsOptions.X, settingsOptions.Y, settingsOptions.COLOR,
        settingsOptions.DATA_POINT],
    [chartTypes.MINI_MAP]: [
        settingsOptions.DATA_POINT,
    ],
};

export const isRelevantOption = (chartType, option, index) => {
    if (
        index > 0 &&
        chartType !== chartTypes.HISTOGRAM &&
        chartType !== chartTypes.COMPOSED
    )
        return false;
    return relevantConfigOptions[chartType]?.includes(option);
};
